import React, {useState, useEffect, useRef} from 'react';
import { makeRequest } from '../Global/makeRequest';
import { Button, FloatingLabel, Form, Container, Row, Col, Image, Table, Modal} from 'react-bootstrap';
import { XLg} from 'react-bootstrap-icons';
import toast, {Toaster} from 'react-hot-toast';
import SettingPren from '../Prenotazione/SettingPren';
//import PieActiveArc from '../Global/Chart';
import { YMD, getInvertiHome, getPercentualeWithout, getPrenotazione } from '../Global/Functions';
import NewGraphic from '../Global/PieChart';
function SettingHome({user, home, close}) {
    const [image, setImage] = useState(null);
    const [listauser, setListaUser] = useState([])
    const [disabled, isDisabled] = useState(false);
    const [disabledImage, isDisabledImage] = useState(false);
    const [nominativo, setNominativo] = useState('');
    const [via, setVia] = useState('');
    const [citta, setCitta] = useState('');
    const [posti, setPosti] = useState(0);
    const [inverti, setInverti] = useState(false);
    const [link, setLink] = useState('');
    const [ordine, setOrdine] = useState(0);
    const [percentuale, setPercentuale] = useState(0);
    const [proprietario, setProprietario] = useState(0);
    const [prenotazione, setOpenPren] = useState(0);
    const [updated, setUpdated] = useState(false);
    const [cedolare, setCedolareTotale] = useState(0.0);
    const [percentualecliente, setPercentualeCliente] = useState(0.0);
    const [tassasoggiorno, setTassaSoggiorno] = useState(0.0);
    const [percentualespesa, setSpesaVal] = useState(0.0);
    const firstRender = useRef(true);
    const secondRender = useRef(true);
    const [modalSpese, showModal] = useState(false);
    const [modalAcconto, showModalAcconto] = useState(false);
    const [newspesa, setNewSpesa] = useState({Nome: '', Prezzo: 0, IsGuadagno: true});
    const [newacconto, setNewAcconto] = useState({Prezzo: 0});
    const updateTotale = (totale, data) => {
        let saldo = 0;
        data.map((item) => {
            return saldo+=item.Quantita;
        })
        setSpesaTotale(parseFloat(totale-saldo).toFixed(2));
    }
    const handleNumber = (e, tipo) => {
        let value = e.target.value;
        if(value < 0) {
            value = 0;
        }
        switch(tipo) {
            case 'spesa': {
                setNewSpesa({...newspesa, Prezzo: parseFloat(value).toFixed(2)})
                break;
            }
            case 'acconto': {
                setNewAcconto({...newacconto, Prezzo: parseFloat(value).toFixed(2)})
                break;
            }
            default: return false;
        }
    }
    const handleSaveAcconto = () => {
        isDisabled(true)
        const soldi = newacconto.Prezzo;
        const proprieta = home;
        const pren = -1
        if(soldi > 0) {
            makeRequest.put('/saldo/insert',{soldi,proprieta,pren})
            .then(res=>{
                toast.success("Acconto/Saldo inserito con successo")
                setUpdated(prev=>!prev)
                setNewAcconto({Prezzo: 0})
                showModalAcconto(false)
                isDisabled(false);
            })
            .catch(err=>{
                toast.error(err.response?.data)
                isDisabled(false);
            })
        } else {
            toast.error("Inserisci una quantità valida");
            isDisabled(false);
        }
    }
    const handleSaveSpese = () => {
        isDisabled(true);
        const motivo = newspesa.Nome;
        const costo = newspesa.Prezzo;
        const isguadagno = newspesa.IsGuadagno;
        const quantita = 1;
        const proprieta = home;
        const prenotazioneid = -1;
        const pagato = 0; 
        const note = '';
        if(motivo.length > 3) {
            if(costo > 0) {
                makeRequest.put('/spese/insert',{motivo, costo, quantita, prenotazioneid, proprieta, pagato, isguadagno, note})
                .then(res=> {
                    toast.success("Spesa inserita con successo");
                    setUpdated(prev=>!prev)
                    setNewSpesa([]);
                    showModal(false);
                })
                .catch(err=> {
                    toast.error("Si è verificato un errore, riprova")
                })
            } else {
                toast.error("Inserisci un prezzo valido");
            }
        } else {
            toast.error("Inserisci un riferimento valido (min. 4 caratteri)");
        }
        isDisabled(false);
    }
    const sendImage = async() => {
        return await Promise.all(
            makeRequest.post('/proprieta/uploadimage',image,{headers: {'Content-Type': 'multipart/form-data'}})
            .then(res=> {
                const patho = res.data.split('uploadedimage/');
                const path = 'uploadedimage/'+patho[1]
                const id = home;
                makeRequest.post('/proprieta/updatephoto',{id,path})
                .then(res=>
                    setLink(path))
            })
        );
    }
    const uploadImage = async(e) => {
        e.preventDefault();
        isDisabledImage(true)
        if(image !== null) {
            const mypromise = sendImage().then(
                isDisabledImage(false)
            );
            toast.promise(mypromise, {
                loading: "Sto caricando...",
                success: "File caricato con successo",
                error: "File caricato con successo"
            },
            {
                error: {
                    icon: '🔥',
                }
            })
        } else {
            toast.error("Inserisci un'immagine valida");
            isDisabledImage(false)
        }
    }
    const calcolaSingolaCedolare = (pren) => {
        let val = 0;
        if(pren.Cedolare > 0) {
            let prezzo = parseFloat(pren.Prezzo.replace(',','.')).toFixed(2);
            let cedolare = pren.PercentualeCedolare/100;
            let hostsicilia = (prezzo-pren.PercentualeOTA)*(parseInt(percentuale)/100)
        //let ota = prenotazione.PercentualeOTA;
            val = parseFloat((prezzo-hostsicilia)*(cedolare)).toFixed(2);
            //console.log('cedolare', cedolare);
        }
        return val;
    }
    const calcolaCedolare = () => {
        let cedolare = 0;
        listanewpren.map((item) => {
            //console.log(item, 'dentro calcola cedolare');
            if(item.Cedolare > 0) {
                const prezzo = parseFloat(item.Prezzo.toFixed(2));
                const hostsicilia = parseFloat((prezzo-item.PercentualeOTA)*(parseInt(percentuale)/100).toFixed(2))
                const cedoperc = item.PercentualeCedolare/100;
                cedolare = parseFloat(cedolare.toFixed(2))+parseFloat((prezzo-hostsicilia)*(cedoperc).toFixed(2));
                //console.log(prezzo, hostsicilia, cedoperc);
                //console.log((prezzo-hostsicilia)*cedoperc)
            }
            return false;
        })
        listaoldpren.map((item) => {
            //console.log(item, 'dentro calcola cedolare');
            if(item.Cedolare > 0) {
                const prezzo = parseFloat(item.Prezzo.toFixed(2));
                const hostsicilia = parseFloat((prezzo-item.PercentualeOTA)*(parseInt(percentuale)/100).toFixed(2))
                const cedoperc = item.PercentualeCedolare/100;
                cedolare = parseFloat(cedolare.toFixed(2))+parseFloat((prezzo-hostsicilia)*(cedoperc).toFixed(2));
                //console.log(prezzo, hostsicilia, cedoperc);
                //console.log((prezzo-hostsicilia)*cedoperc)
            }
            return false;
        })
        //console.log(cedolare, 'totale');
        setCedolareTotale(parseFloat(cedolare).toFixed(2));
    }
    const handleUploadImage = (e) => {
        const formData = new FormData(); 
        formData.append('my-image-file', e.target.files[0], e.target.files[0].name);
        setImage(formData);
    }
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        if(prenotazione === 0) {
            setUpdated(!updated)
        }
    },[prenotazione])
    const calcolaSpese = (arr) => {
        let val = 0.0;
        arr.map((item) => {
            if(item.Motivo !== "Tasse Soggiorno") {
                val = val+(item.Costo*item.Quantita)
            } else {
                val = val-(item.Costo*item.Quantita);
            }
        })
        return parseFloat(val).toFixed(2);
    }
    useEffect(() => {
        if (secondRender.current) {
            secondRender.current = false;
            return;
        }
        const prenotazioni = [];
        listanewpren.map((item) => {
            return prenotazioni.push(item.ID);
        })
        listaoldpren.map((item) => {
            return prenotazioni.push(item.ID);
        })
        calcolaCedolare();
        //console.log(listanewpren, listaoldpren, prenotazioni);
        let totale = 0.0;
        const proprieta = home;
        const updateSpese = async(newArr, totale) => {
            prenotazioni.push(-1);
           // console.log('entro qui', newArr, totale, prenotazioni);
            const selectedyear = user.YearSelected;
            await makeRequest.post('/spese/get',{proprieta, prenotazioni, selectedyear})
            .then(res=> {
                //console.log('lista pren', res.data);
                const speseval = calcolaSpese(res.data);
               // console.log('speseval', speseval);
                newArr = newArr.map((item) => {
                    let value = item.value;
                    for(var i = 0; i < res.data.length; i++) {
                        if(item.label === res.data[i].Motivo) {
                            if(item.label !== "Tasse Soggiorno") {
                                totale=parseFloat(totale.toFixed(2))+parseFloat(res.data[i].Costo.toFixed(2)*res.data[i].Quantita.toFixed(2));
                            } else {
                                totale=parseFloat(totale.toFixed(2))-parseFloat(res.data[i].Costo.toFixed(2)*res.data[i].Quantita.toFixed(2));
                            }
                            value = parseFloat(value.toFixed(2))+parseFloat((res.data[i].Costo.toFixed(2)*res.data[i].Quantita.toFixed(2)));
                        } else if(item.label === "Altro") {
                            if(res.data[i].RifPrenotazione === -1) {
                                totale=parseFloat(totale.toFixed(2))+parseFloat(res.data[i].Costo.toFixed(2)*res.data[i].Quantita.toFixed(2));
                                value = parseFloat(value.toFixed(2))+parseFloat((res.data[i].Costo.toFixed(2)*res.data[i].Quantita.toFixed(2)));
                            }
                        }
                    }
                    if(item.label === "Quota Cliente") {
                      //  console.log('entro qui?');
                        setPercentualeCliente(parseFloat(item.value-(percentualespesa > 0 ? 0 : speseval)).toFixed(2));
                       // console.log('percentuale cliente', parseFloat(item.value-(percentualespesa > 0 ? 0 : speseval)).toFixed(2))
                        //setSpeseVal(speseval);
                        //console.log('quota cliente', parseFloat(item.value-speseval).toFixed(2), item.value, speseval)
                    }
                 //   console.log(item.label, item.value);
                    if(item.label === "Tasse Soggiorno") {
                        setTassaSoggiorno(parseFloat(value).toFixed(2))
                    }
                    return {...item, value: value}
                })
                setSpese(newArr);
                const nuovoArr = res.data.filter((item)=> item.RifPrenotazione === -1)
            //    console.log(res.data)
                setListaSpese(nuovoArr);
            //    console.log(nuovoArr);
                //getPercentualeCliente();
                const selectedyear = user.YearSelected;
                makeRequest.post('/saldo/get',{proprieta, selectedyear})
                .then(res=>{
                    setListaAcconti(res.data)
                    updateTotale(totale,res.data);
                })
                if(percentualespesa === 0 && speseval > 0) {
                //    console.log(speseval, percentualespesa, 'entro orasubito')
                    setSpesaVal(parseFloat(speseval).toFixed(2));
                    setUpdated(prev => !prev);
                }
            })
            .catch(err=>{
             //   console.log('entro qui? errore', err)
                const selectedyear = user.YearSelected;
                makeRequest.post('/saldo/get',{proprieta, selectedyear})
                .then(res=>{
                    setSpese(newArr)
                    setListaAcconti(res.data)
                    updateTotale(totale,res.data);
                })
            })
        }
        const updateHostSicilia = async(totale) => {
            const guadagno = await getPercentualeWithout(proprieta);
            const inverti = await getInvertiHome(proprieta);
            let newArr = [];
            let valuff = 0.0;
            let valper = 0.0;
            const index = prenotazioni.indexOf(-1);
            if (index > -1) {
                prenotazioni.splice(index, 1);
            }
            for(var i = 0; i < prenotazioni.length; i++) {
                const varsupp = await getPrenotazione(prenotazioni[i]);
                const prezzo = parseFloat(varsupp.Prezzo.replace(',','.')).toFixed(2);
                newArr = spese.map((item) => {
                    let value = 0.0;
                    if(item.label === "Quota HostSicilia") {
                        if(varsupp.GuadagnoDisabilitato === 0 && user?.Admin > 0) {
                            value = valuff;
                            value = parseFloat(value)+(prezzo-varsupp.PercentualeOTA/*varsupp.PercentualeOTA > 0 ? prezzo*(parseInt(varsupp.PercentualeOTA)/100) : 0)-(varsupp.Cedolare > 0 ? prezzo*parseInt(varsupp.PercentualeCedolare)/100 : 0)*/)*(parseInt(guadagno)/100)
                            totale = parseFloat(totale)+(prezzo-varsupp.PercentualeOTA/* > 0 ? prezzo*(parseInt(varsupp.PercentualeOTA)/100) : 0)-(varsupp.Cedolare > 0 ? prezzo*parseInt(varsupp.PercentualeCedolare)/100 : 0)*/)*(parseInt(guadagno)/100)
                            //console.log(value, varsupp);
                            valuff = value;
                        }
                    }
                    if(item.label === "Quota Cliente") {
                        if(varsupp.GuadagnoDisabilitato === 0) {
                            if(inverti) {
                                value = valper;
                                //console.log(parseFloat(value),'+',(prezzo-varsupp.PercentualeOTA),'-',((prezzo-varsupp.PercentualeOTA),'*',(parseInt(guadagno)/100)),'-',calcolaSingolaCedolare(varsupp),'-',parseFloat(percentualespesa), 'quota cliente calcolo')
                                value = parseFloat(value)+(prezzo-varsupp.PercentualeOTA/*(varsupp.PercentualeOTA > 0 ? varsupp.Prezzo*(parseInt(varsupp.PercentualeOTA)/100) : 0)*/)-((prezzo-varsupp.PercentualeOTA)*(parseInt(guadagno)/100))-calcolaSingolaCedolare(varsupp);
                                //value = parseFloat(value.toFixed(2))+(prezzo-varsupp.PercentualeOTA/*varsupp.PercentualeOTA > 0 ? prezzo*(parseInt(varsupp.PercentualeOTA)/100) : 0)*/)*(parseInt(100-guadagno)/100)-(varsupp.Cedolare > 0 ? prezzo*parseInt(varsupp.PercentualeCedolare)/100 : 0)
                                //console.log(valper, 'value prima', value, 'value dopo', prezzo, 'prezzo convertito', prezzo-varsupp.PercentualeOTA, 'prezzo - OTA', calcolaSingolaCedolare(varsupp));
                                valper = value;

                                //console.log('ID', varsupp.ID, 'prezzo', prezzo, 'prezzo - percentuale ota', prezzo-varsupp.PercentualeOTA, 'CEDOLARE SECCA:',(varsupp.Cedolare > 0 ? prezzo*parseInt(varsupp.PercentualeCedolare)/100 : 0), 'percentuale HOST SICILIA', (prezzo-varsupp.PercentualeOTA)*(parseInt(guadagno)/100), 'GUADAGNO CLIENTE', (prezzo-varsupp.PercentualeOTA/*(varsupp.PercentualeOTA > 0 ? varsupp.Prezzo*(parseInt(varsupp.PercentualeOTA)/100) : 0)*/)-((prezzo-varsupp.PercentualeOTA)*(parseInt(guadagno)/100))-(varsupp.Cedolare > 0 ? prezzo*parseInt(varsupp.PercentualeCedolare)/100 : 0), 'somma guadagni cliente', valper)
                                //totale = parseFloat(totale.toFixed(2))-(prezzo-varsupp.PercentualeOTA/* > 0 ? prezzo*(parseInt(varsupp.PercentualeOTA)/100) : 0)*/-(varsupp.Cedolare > 0 ? prezzo*parseInt(varsupp.PercentualeCedolare)/100 : 0))*(parseInt(100-guadagno)/100) 
                            }
                        } else {
                            value = valper;
                            value = parseFloat(value)+(prezzo-varsupp.PercentualeOTA)-calcolaSingolaCedolare(varsupp);
                            valper = value;
                        }
                    }
                    if(item.label !== "Quota Cliente") 
                        return {...item, value: parseFloat(value.toFixed(2))}
                    else
                        return {...item, value: parseFloat(value-percentualespesa).toFixed(2)}
                })
            }
            return updateSpese(newArr, totale);
        }
        updateHostSicilia(totale);
    },[updated])
    const openPren = (e) => {
        setOpenPren(e);
    }
    const [listanewpren, setListaNewPren] = useState([]);
    const [listaoldpren, setListaOldPren] = useState([]);
    const [listaacconti, setListaAcconti] = useState([]);
    const datachart = [
        { id: 0, value: 0.0, label: 'Pulizie' },
        { id: 1, value: 0.0, label: 'CheckIn/Out' },
        { id: 2, value: 0.0, label: 'CheckIn/Out Tardivo' },
        { id: 3, value: 0.0, label: 'Biancheria' },
        { id: 4, value: 0.0, label: 'Culla' },
        { id: 5, value: 0.0, label: 'Seggiolone' },
        { id: 6, value: 0.0, label: 'Bagnetto' },
        { id: 7, value: 0.0, label: 'Tasse Soggiorno' },
        { id: 8, value: 0.0, label: 'Altro'},
        { id: 9, value: 0.0, label: 'Quota HostSicilia'},
        { id: 10, value: 0.0, label: 'Quota Cliente'}
    ]
    const [spese, setSpese] = useState(datachart);
    const [listaspese, setListaSpese] = useState([]);
    const [spesatotale, setSpesaTotale] = useState(0.0);
    const handleChangePosti = (e) => {
        const val = e.target.value;
        if(isNaN(val) || val < 0)
            setPosti(0);
        else 
            setPosti(val);
    }
    const handlePercentuale = (e) => {
        const val = e.target.value;
        if(isNaN(val) || val < 0)
            setPercentuale(0);
        else 
            setPercentuale(val);
    }
    const handleDeleteAcconto = (id) => {
        if(window.confirm("Sei sicuro di voler eliminare questo acconto?")) {
            makeRequest.post('/saldo/remove', {id})
            .then(res=> {
                toast.success("Acconto eliminato con successo");
                setUpdated(prev => !prev)
            })
            .catch(err=> {
                toast.error(err.response?.data);
            })
        }
    }
    const handleDeleteSpesa = (id) => {
        if(window.confirm("Sei sicuro di voler eliminare questa spesa?")) {
            makeRequest.post('/spese/remove', {id})
            .then(res=> {
                toast.success("Spesa eliminata con successo");
                setUpdated(prev => !prev)
            })
            .catch(err=> {
                toast.error(err.response?.data);
            })
        }
    }
    const handleClick = () => {
        isDisabled(true);
        if(nominativo.length > 4 && nominativo.length < 200) {
            if(via.length > 3) {
                if(citta.length > 3 && citta.length < 50) {
                    if(!isNaN(posti) && posti > 0) {
                        if(!isNaN(ordine) && ordine > 0) {
                            const id = home;
                            makeRequest.put('/proprieta/modify',{nominativo, citta, via, posti, proprietario, percentuale, inverti, ordine, id})
                            .then(res=> {
                                toast.success('Proprietà modificata con successo')
                                setTimeout(()=>{
                                    close(0);
                                },2000)
                            })
                            .catch(err=> {
                                console.log(err);
                                toast.error(err.response?.data);
                                isDisabled(false);
                            })
                        } else {
                            toast.error("Inserisci un numero di ordine valido ( Min. 1)");
                            isDisabled(false);
                        }
                    } else {
                        toast.error("Inserisci il numero di posti letto (1+)");
                        isDisabled(false);
                    }
                } else {
                    toast.error("Inserisci la città della Proprietà (3-50 caratteri)");
                    isDisabled(false);
                }
            } else {
                toast.error("Inserisci una via valida (minimo 3 caratteri)");
                isDisabled(false);
            }
        } else {
            toast.error("Inserisci un nominativo valido (5-199 caratteri)");
            isDisabled(false);
        }
    }
    useEffect(() => {
        makeRequest.post('/saldo/get',)
        makeRequest.post('/proprieta/getsingle',{home})
        .then(res=> {
            setPosti(res.data[0]?.Posti);
            setProprietario(res.data[0]?.RifProprietario);
            setNominativo(res.data[0]?.Nome);
            setVia(res.data[0]?.Via);
            setCitta(res.data[0]?.Citta);
            setLink(res.data[0]?.Link)
            setPercentuale(res.data[0]?.Guadagno);
            setInverti(res.data[0]?.Inverti);
            setOrdine(res.data[0]?.Ordine);
        })
        .catch(err=>{
            toast.error(err.response?.data);
        })
        const proprieta = home;
        const selectedyear = user.YearSelected;
        makeRequest.post('/saldo/get',{proprieta, selectedyear})
        .then(res=>setListaAcconti(res.data))
        makeRequest.post('/prenotazioni/getnewfromhouse', {proprieta, selectedyear})
        .then(res=> {
            setListaNewPren(res.data)
            setUpdated(Math.floor(Math.random() * 10000));
        }); 
        makeRequest.post('/prenotazioni/getoldfromhouse', {proprieta, selectedyear})
        .then(res=> {
            setListaOldPren(res.data);
            setUpdated(Math.floor(Math.random() * 10000));
        }); 
        makeRequest.get('/user/getlista')
        .then(res=> {
            setListaUser(res.data);
        })
    },[])
    const handleArchiviaCasa = () => {
        if(window.confirm("Sei sicuro di voler eliminare questo appartamento?")) {
            makeRequest.post('/proprieta/remove',{home, selectedyear: user.YearSelected})
            .then(res=>{
                toast.success("Appartamento eliminato con successo");
                close(0);
            })
            .catch(err=>toast.error("Si è verificato un errore, riprova"));
        }
    }
    return (
        <>
        <Toaster/>
        <Row>    
            {prenotazione ? <SettingPren usern={user} pren={prenotazione} close={setOpenPren}/> : <>
            <Col style={{textAlign: 'left'}} xs={6}>
                <Button onClick={() => close(0)} className='closebutton' variant="light"><XLg/></Button>
            </Col>
            <Col style={{textAlign: 'right'}} xs={6}>
                {user?.Admin > 0 ?<Button onClick={handleArchiviaCasa} variant='danger'>Elimina appartamento</Button> : null}
            </Col>
            <Col xs={12} md={6}>
                <h1>Modifica Proprieta</h1>
                <Image
                    src={link}
                    width="200"
                    height="200"
                />
                <FloatingLabel label="Nominativo casa">
                    <Form.Control
                        type="text"
                        defaultValue={nominativo}
                        placeholder="Nominativo casa"
                        onChange={(e) => setNominativo(e.target.value)}
                        className="mb-3"
                    />
                </FloatingLabel>
                <FloatingLabel label="Via">
                    <Form.Control
                        type="text"
                        placeholder="Via"
                        defaultValue={via}
                        onChange={(e) => setVia(e.target.value)}
                        className="mb-3"
                    />
                </FloatingLabel>
                <FloatingLabel label="Città">
                    <Form.Control
                        type="text"
                        placeholder="Città"
                        defaultValue={citta}
                        onChange={(e) => setCitta(e.target.value)}
                        className="mb-3"
                    />
                </FloatingLabel>
                <FloatingLabel label="Posti letto">
                    <Form.Control
                        type="number"
                        placeholder="Posti letto"
                        value={posti}
                        onChange={(e) =>  handleChangePosti(e)}
                        className="mb-3"
                    />
                </FloatingLabel>
                {user?.Admin > 0 ? 
                <Form.Select value={proprietario} className='mb-3' defaultValue={proprietario} onChange={(e) => setProprietario(e.target.value)}>
                    <option value="0">Seleziona Proprietario...</option>
                    {listauser.map((item) => {
                        return <option value={item.ID}>{item.username}</option>
                    })
                    }
                </Form.Select> : null}
                {user?.Admin > 0 ? <>
                <FloatingLabel label="Guadagno (%)">
                    <Form.Control
                        value={percentuale} 
                        className='mb-3'
                        placeholder="Guadagno (%)"
                        type="number"
                        onChange={(e) => handlePercentuale(e)}
                    />
                </FloatingLabel> 
                <Form.Check
                    type="checkbox"
                    label="Inverti guadagno"
                    checked={inverti}
                    onChange={(e) => setInverti(e.target.checked)}
                />
                <FloatingLabel label="Ordine">
                    <Form.Control
                        value={ordine} 
                        className='mb-3'
                        placeholder="Ordine"
                        type="number"
                        onChange={(e) => setOrdine(e.target.value)}
                    />
                </FloatingLabel> 
                </> : null}
                <Button className='mb-3' disabled={disabled} onClick={handleClick} variant="secondary">Salva Proprietà</Button>
                <Form>
                    <Form.Control
                        type="file"
                        onChange={handleUploadImage}
                        accept="image/*"
                        className="mb-3"
                    />
                    <Button type="submit" className='mb-3' disabled={disabledImage} onClick={uploadImage} variant="success">Carica Immagine</Button>
                </Form>
            </Col>
            <Col xs={12} md={6}>
                {(() => {
                    const arr = [];
                    if(percentualecliente-tassasoggiorno > 0)
                        arr.push(<h4>Guadagno cliente: €{percentualecliente-tassasoggiorno}</h4>);
                    if(tassasoggiorno > 0)
                        arr.push(<h4>Tassa di soggiorno: €{tassasoggiorno}</h4>);
                    if(cedolare > 0) 
                        arr.push(<h4>Cedolare secca: €{cedolare}</h4>);
                    if(user?.Admin > 0)
                        arr.push(<h1>Spese totali da rimborsare: {spesatotale}€</h1>);
                    return arr;
                })()}
                {NewGraphic(spese)}
                {user?.Admin > 0 ?<Button style={{margin: '0.5rem'}} className="mb-3" variant="secondary" onClick={() => showModalAcconto(true)}>Inserisci saldo/acconto</Button> : null}
                {user?.Admin > 0 ?<Button style={{margin: '0.5rem'}} className="mb-3" variant="primary" onClick={() => showModal(true)}>Inserisci spesa extra</Button> : null}
                {listanewpren.length ? <><h4>Prenotazioni in arrivo</h4>
                <Table responsive striped hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Cliente</th>
                            <th>Check In</th>
                            <th>Check Out</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(() => {
                            const arr = [];
                            let conto = 0;
                            listanewpren.map((item) => {
                                conto++;
                                return arr.push(<tr key={conto} style={{cursor: 'pointer'}} id={item.ID} onClick={() => openPren(item.ID)}>
                                    <td>{item.ID}.</td>
                                    <td>{item.Nome} {item.Cognome}, {item.Nazionalita}</td>
                                    <td>{YMD(item.DataIn)} {item.OraIn}</td>
                                    <td>{YMD(item.DataOut)} {item.OraOut}</td>
                                </tr>)
                            });
                            return arr;
                        })()}
                    </tbody>
                </Table>
                <hr/></> : null}
                {listaoldpren.length ? <>
                <h4>Prenotazioni passate</h4>
                <Table responsive striped hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Cliente</th>
                            <th>Check In</th>
                            <th>Check Out</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(() => {
                            const arr = [];
                            let conto = 0;
                            listaoldpren.map((item) => {
                                conto++;
                                return arr.push(<tr key={conto} style={{cursor: 'pointer'}} id={item.ID} onClick={() => openPren(item.ID)}>
                                    <td>{item.ID}.</td>
                                    <td>{item.Nome} {item.Cognome}, {item.Nazionalita}</td>
                                    <td>{YMD(item.DataIn)} {item.OraIn}</td>
                                    <td>{YMD(item.DataOut)} {item.OraOut}</td>
                                </tr>)
                            });
                            return arr;
                        })()}
                    </tbody>
                </Table>
                <hr/>
                </> : null }
            </Col>
            {listaspese.length ? 
            <Col xs={12}>
                <h1>Spese Extra</h1>
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Riferimento</th>
                            <th>Costo</th>
                            {user?.Admin > 0 ? <th>Elimina</th> : null }
                        </tr>
                    </thead>
                    <tbody>
                        {(() => {
                            const arr = [];
                            let conto = 0;
                            listaspese.map((item) => {
                                conto++;
                                return arr.push(<tr key={conto}><td>{conto}</td><td>{item?.Motivo}</td><td>{item?.Costo}€</td>{user?.Admin > 0 ? <td><Button variant="danger" onClick={() => handleDeleteSpesa(item.ID)}><XLg/></Button></td> : null}</tr>)
                            })
                            return arr;
                        })()}
                    </tbody>
                </Table>
            </Col> : null }
            {listaacconti.length ? 
            <Col xs={12}>
                <h1>Acconti/Saldi</h1>
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Quantità</th>
                            <th>Data</th>
                            <th>Rif. Prenotazione</th>
                            {user?.Admin > 0 ? <th>Elimina</th> : null }
                        </tr>
                    </thead>
                    <tbody>
                        {(() => {
                            const arr = [];
                            let conto = 0;
                            listaacconti.map((item) => {
                                conto++;
                                return arr.push(<tr key={conto}><td>{conto}</td><td>{item?.Quantita}€</td><td>{YMD(item?.Data)}</td><td>{(item?.RifPrenotazione > -1 ? item?.RifPrenotazione : "No")}</td>{user?.Admin > 0 ? <td><Button variant="danger" onClick={() => handleDeleteAcconto(item.ID)}><XLg/></Button></td> : null}</tr>)
                            })
                            return arr;
                        })()}
                    </tbody>
                </Table>
            </Col> : null }
            </> }
        </Row>
        {user?.Admin > 0 ? <>
        <Modal
            show={modalSpese}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => showModal(false)}
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Inserisci spesa
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Spese Extra</h4>
                <Container>
                    <Row>
                        <Col style={{margin:'0.5rem'}} xs={5}>
                            <FloatingLabel label="Riferimento spesa">
                            <Form.Control
                                type="text"
                                placeholder="Riferimento spesa"
                                onChange={(e) => setNewSpesa({...newspesa, Nome: e.target.value})}
                            />
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin:'0.5rem'}} xs={5}>
                            <FloatingLabel label="€">
                            <Form.Control
                                type="number"
                                placeholder="€"
                                onChange={(e) => handleNumber(e, 'spesa')}
                            />
                            </FloatingLabel>
                        </Col>
                        <Col xs={12}>
                            <Form.Check
                                type="checkbox"
                                label="è un guadagno?"
                                checked={true}
                                onChange={(e) => setNewSpesa({...newspesa, IsGuadagno: e.target.checked})}
                            />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={disabled} variant="success" onClick={handleSaveSpese}>Salva</Button>
                <Button variant="secondary" onClick={() => showModal(false)}>Chiudi</Button>
            </Modal.Footer>
        </Modal>
        <Modal
            show={modalAcconto}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => showModalAcconto(false)}
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Inserisci saldo/acconto
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Saldo/Acconto</h4>
                <Container>
                    <Row>
                        <Col style={{margin:'0.5rem'}} xs={5}>
                            <FloatingLabel label="€">
                            <Form.Control
                                type="number"
                                placeholder="€"
                                onChange={(e) => handleNumber(e, 'acconto')}
                            />
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={disabled} variant="success" onClick={handleSaveAcconto}>Salva</Button>
                <Button variant="secondary" onClick={() => showModalAcconto(false)}>Chiudi</Button>
            </Modal.Footer>
        </Modal> </>: null}
    </>
    )
}
export default SettingHome;