import * as React from 'react';
//import { PieChart } from '@mui/x-charts/PieChart';


export default function PieActiveArc(data, altezza=400, sopra=100) {
  const palette = ['rgb(0, 89, 178)','rgb(46, 150, 255)','rgb(255, 194, 76)','rgb(255, 159, 14)','rgb(243, 130, 0)','rgb(42, 191, 222)','rgb(31, 148, 173)','rgb(189, 44, 56)','rgb(255, 49, 67)','rgb(255, 130, 130)']
  const colors = ['red','blue','yellow','green','gray','black','lightblue', 'pink', 'purple']
  return (
    <div></div>
      /*<PieChart
        colors={palette}
        margin={{ top: sopra, bottom: 100, left: 50, right:50 }}
        slotProps={{
          legend: {
            direction: 'row',
            position: { vertical: 'top', horizontal: 'middle' },
            padding: 0,
          }
        }}
        series={[
        {
          data,
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
        },
        ]}
        height={altezza}
      />*/
  );
}