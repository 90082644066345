import { makeRequest } from "./makeRequest";

export const getDayOfWeek = (d, dayweek) => {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -7+dayweek : dayweek); // adjust when day is sunday
    return new Date(d.setDate(diff));
}
export const formattaDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${year}-${month}-${day}`;
};
export const YMD = (date) => {
  const val = date.split('-');
  return val[2]+'-'+val[1]+'-'+val[0];
}
export const nomeCasa = (id, lista) => {
  const val = lista.filter(item => id === item.ID);
  return val[0].Nome;
}
export const getPercentualeCasa = (id, lista) => {
  const val = lista.filter(item => id === item.ID);
  //console.log('id è ', id, val[0].Guadagno)
  return val[0].Guadagno;
}
export const getCedolare = (id, prenotazioni) => {
  const val = prenotazioni.filter(item => id === item.ID);
  return val[0].Cedolare;
}
export const getPercentualeCedolare = (id, prenotazioni) => {
  const val = prenotazioni.filter(item => id === item.ID);
  return val[0].PercentualeCedolare;
}
export const getPercentualeOTA = (id, prenotazioni) => {
  const val = prenotazioni.filter(item => id === item.ID);
  return val[0].PercentualeOTA;
}
export const calcolaGuadagnoCasa = (id, prenotazioni, idpren, lista, prezzo) => {
  const percentualeOTA = getPercentualeOTA(idpren, prenotazioni)
  const finale = (prezzo-percentualeOTA)*(parseInt(getPercentualeCasa(id,lista))/100)
  //(percentualeOTA > 0 ? prezzo*(parseInt(percentualeOTA)/100) : 0)*/-/*(getCedolare(idpren, prenotazioni) > 0 ? prezzo*(parseInt(getPercentualeCedolare(idpren,prenotazioni))/100) : 0))*/
  return finale;
}
export const getPercentualeWithout = async(home) => {
  let supp = 0;
  await makeRequest.post('/proprieta/getsingle',{home})
  .then(res=> {
    supp = res.data[0].Guadagno}
  )
  return supp;
}
export const getInvertiHome = async(home) => {
  let supp = 0;
  await makeRequest.post('/proprieta/getsingle',{home})
  .then(res=> {
    supp = res.data[0].Inverti
  })
  return supp;
}
export const getPrenotazione = async(id) => {
  let supp = 0;
  await makeRequest.post('/prenotazioni/get',{id})
  .then(res=> {
    supp = res.data[0];
  })
  return supp;
}
export function validateHhMm(inputField) {
  var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(inputField);
  return isValid;
}
export const isvalidemail = (email) => {
    let val = false;
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if ( re.test(email) ) {
        val = true;
    }
    return val;
}
export const isValidPren = (prenId, listapren) => {
  const val = listapren.filter(item => prenId === item.ID);
  if(val)
    return true;
  else
    return false;
}