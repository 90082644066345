import React, { useState, useEffect, useContext, useRef } from 'react';
import {Container, Row, Col, Button, Modal, FloatingLabel, Form, Table} from 'react-bootstrap';
import MenuNav from '../Global/MenuNav';
import { makeRequest } from '../Global/makeRequest';
import { AuthContext } from '../../Auth/authContext';
import { useNavigate } from 'react-router-dom';
import { HandleLogout } from '../Global/Logout';
import {Gear, XLg } from 'react-bootstrap-icons';
//import PieActiveArc from '../Global/Chart';
import NewGraphic from '../Global/NewChart';
import toast, {Toaster} from 'react-hot-toast';
import { YMD, calcolaGuadagnoCasa, getPercentualeCasa, isValidPren, nomeCasa } from '../Global/Functions';
import SettingPren from '../Prenotazione/SettingPren';
import NewGraphicBar from '../Global/NewChart';
function Contabilita() {
    const [listaspese, setListaSpese] = useState([]);
    const [listaproprieta, setListaProprieta] = useState([]);
    const [listaguadagno, setListaGuadagno] = useState([]);
    const [listaraggruppamenti, setListaRaggruppamenti] = useState([])
    const [raggruppamento, setRaggruppamento] = useState(0);
    const [handleNewRaggruppa, setHandleNewRaggruppa] = useState(0)
    const [raggruppa, setRaggruppa] = useState(false);
    const [listaguadagnofromproprieta, setListaGuadagnoFromProprieta] = useState([])
    const [listaguadagnofromspese, setListaGuadagnoFromSpese] = useState([])
    const [listaprenotazioni, setListaPrenotazioni] = useState([]);
    const [listamotivi, setListaMotivi] = useState([]);
    const [listamesi, setListaMesi] = useState([]);
    const [filtro, setFilter] = useState(null);
    const [totalespese, setTotaleSpese] = useState(0.0);
    const [totaleguadagno, setTotaleGuadagno] = useState(0.0)
    const [modalSpese, showModal] = useState(false);
    const [modalGuadagno, showModalGuadagno] = useState(false);
    const [modalHandleGuadagno, showModalHandleGuadagno] = useState(false);
    const [modalHandleRaggruppa, showModalHandleRaggruppa] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [pren, setPren] = useState(0);
    const [yearselection, setYearSelected] = useState(null)
    const [filtraproprieta, setFiltraProprieta] = useState(false);
    const handleSettings = (id) => {
        console.log('entro', id)
        setPren(id);
        setUpdated(prev=>!prev);
    }
    const [dataguadagno, setDataGuadagno] = useState([{id:0, label:"Nessuno", value:0}]);
    const { logout } = useContext(AuthContext);
    const date = new Date();
    const formatDate = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate())+'-'+(date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1)+'-'+date.getFullYear();
    const navigate = useNavigate();
    const [newspesa, setNewSpesa] = useState({Nome: 'Dipendenti', Prezzo: 0.0, Note: '', Pagato: 0})
    const [newguadagno, setNewGuadagno] = useState({Nome: 'Colazione', Prezzo: 0.0, Riferimento: '', Pagato: 0, Raggruppato: 0})
    const [handleGuadagno, setHandleGuadagno] = useState({ID: 0, Motivo: '', Riferimento: '', Costo: 0, Pagato: 0, Raggruppato: 0})
    const [disabled, isDisabled] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [modalHandleSpesa, showModalHandleSpesa] = useState(false);
    const myRef = useRef(null);
    const executeScroll = () => myRef.current.scrollIntoView()
    const [handleItem, setHandleItem] = useState({ID: 0, Motivo: '', Costo: 0.0, Note: '', Pagato: 0});
    const handleRaggruppa = () => {
        toast("Seleziona i guadagni da raggruppare")
        executeScroll();
        setRaggruppa(true);
    }
    const handleSaveRaggruppo = () => {
        setRaggruppa(false);
    }

    const handleSaveCheck = (id, e) => {
        const value = e.target.checked;
        const newArr = listaguadagno.map((item) => {
            if(item.ID === id) {
                return {...item, checked: value}
            }
        })
        setListaGuadagno(newArr);
    }
    const handleSpesa = (id) => {
        console.log(id);
        makeRequest.post('/spese/getsingle',{id})
        .then(res=> {
            setHandleItem(res.data[0])
            console.log(res.data[0])
            showModalHandleSpesa(true);
        })
        .catch(err=>toast.error(err.response?.data))
    }
    const handleModifySpesa = () => {
        isDisabled(true);
        const motivo = handleItem.Motivo;
        const costo = handleItem.Costo;
        const quantita = 1;
        const proprieta = -1;
        const prenotazioneid = -1;
        const note = handleItem.Note;
        const pagato = handleItem.Pagato;
        const id = handleItem.ID;
        if(motivo.length > 3) {
            if(costo > 0) {
                makeRequest.put('/spese/modify',{id, motivo, costo, quantita, note, pagato, prenotazioneid, proprieta})
                .then(res=> {
                    toast.success("Spesa modificata con successo");
                    setUpdated(prev=>!prev)
                    setHandleItem({Nome: '', Prezzo: 0.0, Note: '', Pagato: 0});
                    showModalHandleSpesa(false);
                })
                .catch(err=> {
                    toast.error("Si è verificato un errore, riprova")
                })
            } else {
                toast.error("Inserisci un prezzo valido");
            }
        } else {
            toast.error("Inserisci un riferimento valido (min. 4 caratteri)");
        }
        isDisabled(false);
    }
    const handleDeleteSpesa = () => {
        const id = handleItem.ID;
        if(window.confirm("Sei sicuro di voler eliminare questa spesa?")) {
            makeRequest.post('/spese/remove',{id})
            .then(res=>{
                setUpdated(prev => !prev)
                showModalHandleSpesa(false);
                setHandleItem({ID: 0, Motivo: '', Costo: 0.0, Note: '', Pagato: 0})
                toast.success("Eliminato con successo")

            })
            .catch(err=>{
                toast.error(err.response?.data)
            })
        }
    }
    const handleDeleteGuadagno = () => {
        const id = handleGuadagno.ID;
        if(window.confirm("Sei sicuro di voler eliminare questo guadagno?")) {
            makeRequest.post('/guadagni/remove',{id})
            .then(res=>{
                setUpdated(prev=> !prev)
                showModalHandleGuadagno(false)
                toast.success("Eliminato con successo")
            })
            .catch(err=>{
                toast.error(err.response?.data)
            })
        }
    }
    const handleNumber = (e, tipo) => {
        let value = e.target.value;
        if(value < 0) {
            value = 0;
        }
        switch(tipo) {
            case 'spesa': {
                setNewSpesa({...newspesa, Prezzo: parseFloat(value).toFixed(2)})
                break;
            }
            case 'guadagno': {
                setNewGuadagno({...newguadagno, Prezzo: parseFloat(value).toFixed(2)})
                break;
            }
            case 'modifyspesa': {
                setHandleItem({...handleItem, Costo: parseFloat(value).toFixed(2)})
                break;
            }
            default: return false;
        }
    }
    const gestisciSpese = (data) => {
        let totale = 0.0;
        const newArr = spese.map((item) => {
            let value = 0.0;
            for(var i = 0; i < data.length; i++) {
                if(item.label === data[i].Motivo) {
                    if(filtro !== null) {
                        if(data[i].DataInserimento.search(filtro) > -1) {
                            if(yearselection == '2024') {
                                if(data[i].IsGuadagno) {
                                    value = parseFloat(value.toFixed(2))+parseFloat((data[i].Costo.toFixed(2)*data[i].Quantita.toFixed(2)));
                                    totale = parseFloat(totale.toFixed(2))+parseFloat((data[i].Costo.toFixed(2)*data[i].Quantita.toFixed(2)));
                                }
                            } else {
                                if(!data[i].IsGuadagno) {
                                    value = parseFloat(value.toFixed(2))+parseFloat((data[i].Costo.toFixed(2)*data[i].Quantita.toFixed(2)));
                                    totale = parseFloat(totale.toFixed(2))+parseFloat((data[i].Costo.toFixed(2)*data[i].Quantita.toFixed(2)));
                                }
                            }
                        }
                    } else {
                        if(yearselection == '2024') {
                            if(data[i].IsGuadagno) {
                                value = parseFloat(value.toFixed(2))+parseFloat((data[i].Costo.toFixed(2)*data[i].Quantita.toFixed(2)));
                                totale = parseFloat(totale.toFixed(2))+parseFloat((data[i].Costo.toFixed(2)*data[i].Quantita.toFixed(2)));
                            }
                        } else {
                            if(!data[i].IsGuadagno) {
                                value = parseFloat(value.toFixed(2))+parseFloat((data[i].Costo.toFixed(2)*data[i].Quantita.toFixed(2)));
                                totale = parseFloat(totale.toFixed(2))+parseFloat((data[i].Costo.toFixed(2)*data[i].Quantita.toFixed(2)));
                            }
                        }
                    }
                }
            }
            return {...item, value: value}
        })
        setSpese(newArr);
        setTotaleSpese(parseFloat(totale.toFixed(2)));
    }
    const handleModGuadagno = (id) => {
        console.log(id);
        makeRequest.post('/guadagni/getsingle',{id})
        .then(res=> {
            setHandleGuadagno(res.data[0])
            showModalHandleGuadagno(true);
        })
        .catch(err=>toast.error(err.response?.data))
    }
    const handleModifyGuadagno = () => {
        isDisabled(true);
        const id = handleGuadagno.ID;
        const motivo = handleGuadagno.Motivo;
        const riferimento = handleGuadagno.Riferimento;
        const costo = handleGuadagno.Costo;
        const pagato = 1;
        const raggruppato = handleGuadagno.Raggruppato;
        if(motivo.length > 3) {
            if(costo > 0) {
                makeRequest.post('/guadagni/modify',{motivo, riferimento, costo, pagato, raggruppato, id})
                .then(res=> {
                    toast.success("Guadagno modificato con successo");
                    isDisabled(false);
                    makeRequest.post('/guadagni/getall', {selectedyear: currentUser?.YearSelected})
                    .then(res=> {
                        const newArr = res.data.map((item) => {
                            return {...item, checked: false};
                        })
                        setListaGuadagno(newArr)
                        makeRequest.post('/guadagni/getmotivi',{selectedyear: currentUser?.YearSelected})
                        .then(res=>{
                            setListaMotivi(res.data)
                            setUpdated(prev => !prev);
                        })
                    })
                    setHandleGuadagno({Motivo: '', Costo: 0.0, Riferimento: '', Pagato: 0, ID: 0});
                    showModalHandleGuadagno(false);
                })
                .catch(err=> {
                    isDisabled(false);
                    toast.error("Si è verificato un errore, riprova")
                })
            } else {
                toast.error("Inserisci un prezzo valido");
            }
        } else {
            toast.error("Inserisci un riferimento valido (min. 4 caratteri)");
        }
    }
    const handleSaveGuadagno = () => {
        isDisabled(true);
        const motivo = newguadagno.Nome;
        const riferimento = newguadagno.Riferimento;
        const costo = newguadagno.Prezzo;
        const pagato = 1;
        const raggruppato = newguadagno.Raggruppato;
        if(motivo.length > 3) {
            if(costo > 0) {
                makeRequest.put('/guadagni/insert',{motivo, riferimento, costo, pagato, raggruppato})
                .then(res=> {
                    toast.success("Guadagno inserito con successo");
                    makeRequest.post('/guadagni/getall',{selectedyear: currentUser?.YearSelected})
                    .then(res=> {
                        const newArr = res.data.map((item) => {
                            return {...item, checked: false};
                        })
                        setListaGuadagno(newArr)
                        makeRequest.post('/guadagni/getmotivi',{selectedyear: currentUser?.YearSelected})
                        .then(res=>{
                            setListaMotivi(res.data)
                            setUpdated(prev => !prev);
                        })
                    })
                    setNewGuadagno({Nome: 'Colazione', Prezzo: 0.0, Riferimento: ''});
                    showModalGuadagno(false);
                })
                .catch(err=> {
                    toast.error("Si è verificato un errore, riprova")
                })
            } else {
                toast.error("Inserisci un prezzo valido");
            }
        } else {
            toast.error("Inserisci un riferimento valido (min. 4 caratteri)");
        }
        isDisabled(false);
    }
    const handleSaveSpese = () => {
        isDisabled(true);
        const motivo = newspesa.Nome;
        const costo = newspesa.Prezzo;
        const quantita = 1;
        const proprieta = -1;
        const isguadagno = 0;
        const prenotazioneid = -1;
        const note = newspesa.Note;
        const pagato = newspesa.Pagato;
        if(motivo.length > 3) {
            if(costo > 0) {
                makeRequest.put('/spese/insert',{motivo, costo, quantita, note, pagato, prenotazioneid, isguadagno, proprieta})
                .then(res=> {
                    toast.success("Spesa inserita con successo");
                    setUpdated(prev=>!prev)
                    setNewSpesa({Nome: 'Dipendenti', Prezzo: 0.0, Note: '', Pagato: 0});
                    showModal(false);
                })
                .catch(err=> {
                    toast.error("Si è verificato un errore, riprova")
                })
            } else {
                toast.error("Inserisci un prezzo valido");
            }
        } else {
            toast.error("Inserisci un riferimento valido (min. 4 caratteri)");
        }
        isDisabled(false);
    }
    const getGuadagnoProprieta = (data) => {
        const proprieta = data.map((item) => {
            return item.ID;
        })
        const guadagno = data.map((item) => {
            return item.Guadagno;
        })
        const selectedyear = currentUser?.YearSelected;
        makeRequest.post('/prenotazioni/getall',{selectedyear})
        .then(res=>{
            setUpdated(prev => !prev)
            setListaPrenotazioni(res.data)
        })
        .catch(err=>console.log(err))
        makeRequest.post('/guadagni/getall', {selectedyear})
        .then(res=> {
            setUpdated(prev => !prev)
            const newArr = res.data.map((item) => {
                return {...item, checked: false};
            })
            setListaGuadagno(newArr)
        })
        makeRequest.post('/spese/getfromproprieta',{proprieta, selectedyear})
        .then(res=> {
            setUpdated(prev => !prev)
            setListaGuadagnoFromProprieta(res.data)
        })
        .catch(err=>console.log(err))
        makeRequest.post('/spese/getfromproprietanopren',{proprieta, selectedyear})
        .then(res=> {
            setUpdated(prev => !prev)
            setListaGuadagnoFromSpese(res.data)
        })
        .catch(err=>console.log(err))
    } 
    const setGuadagnoHome = () => {
        let totale = 0.0
        const newArr = speseHome.map((item) => {
            let value = 0.0
            for(var i = 0; i < listaguadagnofromproprieta.length; i++) {
                if(isValidPren(listaguadagnofromproprieta[i].RifPrenotazione, listaprenotazioni)) {
                    if(filtro !== null) {
                        if(filtraproprieta === false) {
                            if(listaguadagnofromproprieta[i].DataInserimento.search(filtro) > -1) {
                                if(item.label === listaguadagnofromproprieta[i].Motivo) {
                                    totale=parseFloat(totale.toFixed(2))+parseFloat(listaguadagnofromproprieta[i].Costo.toFixed(2)*listaguadagnofromproprieta[i].Quantita.toFixed(2));
                                    value = parseFloat(value.toFixed(2))+parseFloat(listaguadagnofromproprieta[i].Costo.toFixed(2)*listaguadagnofromproprieta[i].Quantita.toFixed(2));
                                }
                            }
                        } else {
                            if(filtraproprieta === listaguadagnofromproprieta[i].RifProprieta) {
                                if(listaguadagnofromproprieta[i].DataInserimento.search(filtro) > -1) {
                                    if(item.label === listaguadagnofromproprieta[i].Motivo) {
                                        totale=parseFloat(totale.toFixed(2))+parseFloat(listaguadagnofromproprieta[i].Costo.toFixed(2)*listaguadagnofromproprieta[i].Quantita.toFixed(2));
                                        value = parseFloat(value.toFixed(2))+parseFloat(listaguadagnofromproprieta[i].Costo.toFixed(2)*listaguadagnofromproprieta[i].Quantita.toFixed(2));
                                    }
                                }
                            }
                        }
                    } else {
                        if(filtraproprieta === false) {
                            if(item.label === listaguadagnofromproprieta[i].Motivo) {
                                totale=parseFloat(totale.toFixed(2))+parseFloat(listaguadagnofromproprieta[i].Costo.toFixed(2)*listaguadagnofromproprieta[i].Quantita.toFixed(2));
                                value = parseFloat(value.toFixed(2))+parseFloat(listaguadagnofromproprieta[i].Costo.toFixed(2)*listaguadagnofromproprieta[i].Quantita.toFixed(2));
                            }
                        } else {
                            if(filtraproprieta === listaguadagnofromproprieta[i].RifProprieta) {
                                if(item.label === listaguadagnofromproprieta[i].Motivo) {
                                    totale=parseFloat(totale.toFixed(2))+parseFloat(listaguadagnofromproprieta[i].Costo.toFixed(2)*listaguadagnofromproprieta[i].Quantita.toFixed(2));
                                    value = parseFloat(value.toFixed(2))+parseFloat(listaguadagnofromproprieta[i].Costo.toFixed(2)*listaguadagnofromproprieta[i].Quantita.toFixed(2));
                                }
                            }
                        }
                    }
                }
            }
            return {...item, value: parseFloat(value.toFixed(2))}
        })
        setSpeseHome(newArr);
    }
    const setGuadagno = () => {
        const arrFinale = []
        let totale = 0.0;
        let count = 1000;
        for(var i = 0; i < listaproprieta.length; i++) {
            let var1 = {id: count, label: listaproprieta[i].Nome, value: 0.0}
            count++;
            listaguadagnofromproprieta.map((item) => {
                if(item.RifProprieta === listaproprieta[i].ID) {
                    if(isValidPren(item.RifPrenotazione, listaprenotazioni)) {
                        if(filtro !== null) {
                            if(filtraproprieta === false) {
                                if(item.DataInserimento.search(filtro) > -1) {
                                    if(item.IsGuadagno) {
                                    /*if(item.Motivo !== "Tasse Soggiorno") {
                                        totale+=item.Costo*item.Quantita;
                                        var1.value+=item.Costo*item.Quantita;
                                    } else {*/
                                        totale+=item.Costo*item.Quantita;
                                        var1.value+=item.Costo*item.Quantita;                             
                                    }
                                }
                            } else {
                                if(filtraproprieta === item.RifProprieta) {
                                    if(item.DataInserimento.search(filtro) > -1) {
                                        if(item.IsGuadagno) {
                                        /*if(item.Motivo !== "Tasse Soggiorno") {
                                            totale+=item.Costo*item.Quantita;
                                            var1.value+=item.Costo*item.Quantita;
                                        } else {*/
                                            totale+=item.Costo*item.Quantita;
                                            var1.value+=item.Costo*item.Quantita;                             
                                        }
                                    }
                                }
                            }
                        } else {
                            if(filtraproprieta === false) {
                                if(item.IsGuadagno) {
                                    totale+=item.Costo*item.Quantita;
                                    var1.value+=item.Costo*item.Quantita;                     
                                }
                            } else {
                                if(filtraproprieta === item.RifProprieta) {
                                    if(item.IsGuadagno) {
                                        totale+=item.Costo*item.Quantita;
                                        var1.value+=item.Costo*item.Quantita;                     
                                    }
                                }
                            }
                        }
                    }
                } 
            })
            listaprenotazioni.map((item) => {
                if(item.RifProprieta === listaproprieta[i].ID) {
                    if(filtro !== null) {
                        if(filtraproprieta === false) {
                            if(item.DataOut.search(filtro) > -1) {
                                if(item.Pagato) {
                                    if(item.GuadagnoDisabilitato === 0) {
                                        totale+=(item.Prezzo-item.PercentualeOTA)/*(item.PercentualeOTA > 0 ? item.Prezzo*(parseInt(item.PercentualeOTA)/100) : 0)-(item.Cedolare > 0 ? item.Prezzo*parseInt(item.PercentualeCedolare)/100 : 0))*/*(parseInt(listaproprieta[i].Guadagno)/100)
                                        var1.value+=(item.Prezzo-item.PercentualeOTA)/*(item.PercentualeOTA > 0 ? item.Prezzo*(parseInt(item.PercentualeOTA)/100) : 0)-(item.Cedolare > 0 ? item.Prezzo*parseInt(item.PercentualeCedolare)/100 : 0))*/*(parseInt(listaproprieta[i].Guadagno)/100)
                                    }
                                }
                            }
                        } else {
                            if(filtraproprieta === item.RifProprieta) {
                                if(item.DataOut.search(filtro) > -1) {
                                    if(item.Pagato) {
                                        if(item.GuadagnoDisabilitato === 0) {
                                            totale+=(item.Prezzo-item.PercentualeOTA)/*(item.PercentualeOTA > 0 ? item.Prezzo*(parseInt(item.PercentualeOTA)/100) : 0)-(item.Cedolare > 0 ? item.Prezzo*parseInt(item.PercentualeCedolare)/100 : 0))*/*(parseInt(listaproprieta[i].Guadagno)/100)
                                            var1.value+=(item.Prezzo-item.PercentualeOTA)/*(item.PercentualeOTA > 0 ? item.Prezzo*(parseInt(item.PercentualeOTA)/100) : 0)-(item.Cedolare > 0 ? item.Prezzo*parseInt(item.PercentualeCedolare)/100 : 0))*/*(parseInt(listaproprieta[i].Guadagno)/100)
                                        }
                                    }
                                }  
                            }
                        }
                    } else {
                        if(filtraproprieta === false) {
                            if(item.Pagato) {
                                if(item.GuadagnoDisabilitato === 0) {
                                    totale+=(item.Prezzo-item.PercentualeOTA)/*(item.PercentualeOTA > 0 ? item.Prezzo*(parseInt(item.PercentualeOTA)/100) : 0)-(item.Cedolare > 0 ? item.Prezzo*parseInt(item.PercentualeCedolare)/100 : 0))*/*(parseInt(listaproprieta[i].Guadagno)/100)
                                    var1.value+=(item.Prezzo-item.PercentualeOTA)/*(item.PercentualeOTA > 0 ? item.Prezzo*(parseInt(item.PercentualeOTA)/100) : 0)-(item.Cedolare > 0 ? item.Prezzo*parseInt(item.PercentualeCedolare)/100 : 0))*/*(parseInt(listaproprieta[i].Guadagno)/100)
                                }
                            }
                        } else {
                            if(filtraproprieta === item.RifProprieta) {
                                if(item.Pagato) {
                                    if(item.GuadagnoDisabilitato === 0) {
                                        totale+=(item.Prezzo-item.PercentualeOTA)/*(item.PercentualeOTA > 0 ? item.Prezzo*(parseInt(item.PercentualeOTA)/100) : 0)-(item.Cedolare > 0 ? item.Prezzo*parseInt(item.PercentualeCedolare)/100 : 0))*/*(parseInt(listaproprieta[i].Guadagno)/100)
                                        var1.value+=(item.Prezzo-item.PercentualeOTA)/*(item.PercentualeOTA > 0 ? item.Prezzo*(parseInt(item.PercentualeOTA)/100) : 0)-(item.Cedolare > 0 ? item.Prezzo*parseInt(item.PercentualeCedolare)/100 : 0))*/*(parseInt(listaproprieta[i].Guadagno)/100)
                                    }
                                }
                            }
                        }
                    }
                }
            })
            listaguadagnofromspese.map((item) => {
                if(item.RifProprieta === listaproprieta[i].ID) {
                    if(filtro !== null) {
                        if(filtraproprieta === false) {
                            if(item.DataInserimento.search(filtro) > -1) {
                                if(item.IsGuadagno) {
                                    totale+=item.Costo*item.Quantita;
                                    var1.value+=item.Costo*item.Quantita;                             
                                }
                            }
                        } else {
                            if(filtraproprieta === item.RifProprieta) {
                                if(item.DataInserimento.search(filtro) > -1) {
                                    if(item.IsGuadagno) {
                                        totale+=item.Costo*item.Quantita;
                                        var1.value+=item.Costo*item.Quantita;                             
                                    }
                                }
                            }
                        }
                    } else {
                        if(filtraproprieta === false) {
                            if(item.IsGuadagno) {
                                totale+=item.Costo*item.Quantita;
                                var1.value+=item.Costo*item.Quantita
                            }
                        } else {
                            if(filtraproprieta === item.RifProprieta) {
                                if(item.IsGuadagno) {
                                    totale+=item.Costo*item.Quantita;
                                    var1.value+=item.Costo*item.Quantita
                                }
                            }
                        }
                    }
                }
            })
            arrFinale.push(var1);
        }
        for(var i = 0; i < listamotivi.length; i++) {
            count++;
            arrFinale.push({id: count, label: listamotivi[i].Motivo, value: 0.0});
        }
        listaguadagno.map((item) => {
            for(var i = 0; i < arrFinale.length; i++) {
                if(filtro !== null) {
                    if(filtraproprieta === false) {
                        if(item.DataInserimento.search(filtro) > -1) {
                            if(item.Motivo === arrFinale[i].label) {
                                totale+=item.Costo;
                                arrFinale[i].value+=item.Costo;
                            }
                        }
                    } else {
                        if(filtraproprieta === item.Raggruppato) {
                            if(item.DataInserimento.search(filtro) > -1) {
                                if(item.Motivo === arrFinale[i].label) {
                                    totale+=item.Costo;
                                    arrFinale[i].value+=item.Costo;
                                }
                            }
                        }
                    }
                } else {
                    if(filtraproprieta === false) {
                        if(item.Motivo === arrFinale[i].label) {
                            totale+=item.Costo;
                            arrFinale[i].value+=item.Costo;
                        }
                    } else {
                        if(filtraproprieta === item.Raggruppato) {
                            if(item.Motivo === arrFinale[i].label) {
                                totale+=item.Costo;
                                arrFinale[i].value+=item.Costo;
                            }
                        }
                    }
                }   
            }
        })
        setDataGuadagno(arrFinale)
        setTotaleGuadagno(parseFloat(totale.toFixed(2)));
        getMonthYearGuadagno();
    }
    const getMonthYearGuadagno = () => {
        const datepren = listaprenotazioni.map((item) => {return item.DataIn})
        const dateguadagno = listaguadagno.map((item) => {return item.DataInserimento})
        const datespese = listaspese.map((item)=> {return item.DataInserimento});
        const dataguadagnoproprieta = listaguadagnofromproprieta.map((item) =>  {return item.DataInserimento});
        const dataguadagnospese = listaguadagnofromspese.map((item) => {return item.DataInserimento});
        const arrfinale = [];
        datepren.map((item) => {
            const replace = item.split("-");
            const datefinal = replace[1]+'/'+replace[0];
            if(!arrfinale.includes(datefinal))
                arrfinale.push(datefinal);
            return item;
        })
        dateguadagno.map((item) => {
            const replace = item.split("-");
            const datefinal = replace[1]+'/'+replace[0];
            if(!arrfinale.includes(datefinal))
                arrfinale.push(datefinal);
        })
        datespese.map((item) => {
            const replace = item.split("-");
            const datefinal = replace[1]+'/'+replace[0];
            if(!arrfinale.includes(datefinal))
                arrfinale.push(datefinal);
        })
        dataguadagnoproprieta.map((item) => {
            const replace = item.split("-");
            const datefinal = replace[1]+'/'+replace[0];
            if(!arrfinale.includes(datefinal))
                arrfinale.push(datefinal);
        })
        dataguadagnospese.map((item) => {
            const replace = item.split("-");
            const datefinal = replace[1]+'/'+replace[0];
            if(!arrfinale.includes(datefinal))
                arrfinale.push(datefinal);
        })
        arrfinale.sort()
        setListaMesi(arrfinale)
    }
    const leaveFilter = () => {
        setFilter(null);
        setFiltraProprieta(false);
        setUpdated(!updated);
    }
    useEffect(() => {
        setUpdated(prev=> !prev);
        console.log('filtraproprieta è ora', filtraproprieta)
    },[filtraproprieta])
    const handleFilter = (data) => {
        const datanew = data.item.split("/");
        const filtro = datanew[1]+'-'+datanew[0];
        setFilter(filtro);
        setUpdated(!updated);
    }
    useEffect(() => {
        makeRequest.get('/user/auth')
        .then(res=> {
            setCurrentUser(res.data[0])
            if(!res.data[0].Admin)
                navigate('/calendario');
            setYearSelected(res.data[0].YearSelected);
        })
        .catch(err => {
            if(err.response?.status === 403)
                HandleLogout(logout,navigate);
        })
    },[])
    useEffect(() => {
        if(yearselection !== null) {
            const selectedyear = yearselection;
            makeRequest.post('/proprieta/getall',{selectedyear})
            .then(res=>{
                setListaProprieta(res.data)
                getGuadagnoProprieta(res.data);
            })
            .catch(err=>console.log(err))
            makeRequest.post('/guadagni/getmotivi',{selectedyear})
            .then(res=>setListaMotivi(res.data))
            makeRequest.get('/raggruppamenti/get')
            .then(res=>setListaRaggruppamenti(res.data))
        }
    },[yearselection])
    useEffect(() => {
        setGuadagno();
        setGuadagnoHome();
    },[listaguadagno, listaproprieta, listaguadagnofromproprieta, listamotivi, listaguadagnofromspese])
    useEffect(() => {
        const proprieta = -1
        const prenotazioni = -1;
        const selectedyear = currentUser?.YearSelected;
        makeRequest.post('/spese/get/',{proprieta, prenotazioni, selectedyear})
        .then(res=> {
            setListaSpese(res.data);
            gestisciSpese(res.data);
        })
        .catch(err=>console.log(err))
        makeRequest.post('/guadagni/getall', {selectedyear})
        .then(res=>{
            const newArr = res.data.map((item) => {
                return {...item, checked: false}
            })
            setListaGuadagno(newArr);
            console.log(newArr);
        })
    }, [updated])
    const datachart = [
        { id: 0, value: 0, label: 'Dipendenti' },
        { id: 1, value: 0, label: 'Acquaviva' },
        { id: 2, value: 0, label: 'Manutenzione' },
        { id: 3, value: 0, label: 'Nonsolocaffè' },
        { id: 4, value: 0, label: 'Lavanderia' },
        { id: 5, value: 0, label: 'AI.RÒ' },
        { id: 7, value: 0, label: 'Supermercato' },
        { id: 9, value: 0, label: 'Pulizie'},
        { id: 10, value: 0, label: 'F24'},
        { id: 11, value: 0, label: 'Condominio'},
        { id: 12, value: 0, label: 'Luce'},
        { id: 13, value: 0, label: 'Affitti'},
        { id: 14, value: 0, label: 'Consulente'},
        { id: 8, value: 0, label: 'Altro'},
        
    ]
    const [spese, setSpese] = useState(datachart);

    const datachartspese = [
        { id: 0, value: 0.0, label: 'Pulizie' },
        { id: 1, value: 0.0, label: 'CheckIn/Out' },
        { id: 2, value: 0.0, label: 'CheckIn/Out Tardivo' },
        { id: 3, value: 0.0, label: 'Biancheria' },
        { id: 4, value: 0.0, label: 'Culla' },
        { id: 5, value: 0.0, label: 'Seggiolone' },
        { id: 6, value: 0.0, label: 'Bagnetto' },
        { id: 8, value: 0.0, label: 'Altro'},
    ]
    const [speseHome, setSpeseHome] = useState(datachartspese);

    return(
        <>
        <Container className="dashboard" fluid>
            <Toaster/>
            <Row>
                <Col xs={12}>
                    <MenuNav user={currentUser} active={{path: '/contabilita'}}/>
                </Col>
                {pren ? <SettingPren usern={currentUser} pren={pren} close={handleSettings}/> :
                <>
                <Col xs={12} style={{textAlign: 'right'}}>
                    <h3 style={{marginRight: '2rem'}}>Contabilità</h3>
                </Col>
                {filtro === null && filtraproprieta === false ?
                <>
                    <Col xs={6} style={{textAlign: 'left'}}>
                        <Button style={{marginLeft: '2rem'}} onClick={() => showModalHandleRaggruppa(true)} variant="danger">Filtro proprietà</Button>
                    </Col>
                    <Col xs={6} style={{textAlign: 'right'}}>
                        <Button style={{marginRight: '2rem'}} onClick={() => showModalGuadagno(true)} variant="success">Aggiungi guadagno</Button>
                        <Button style={{marginRight: '2rem'}} onClick={() => showModal(true)} variant="primary">Aggiungi spesa</Button>
                    </Col> 
                </>
                : 
                <>
                <Col xs={6} style={{textAlign: 'left'}}>
                    <Button onClick={() => leaveFilter()} className='closebutton' variant="light"><XLg/></Button>
                </Col>
                </>}
                <Col xs={12} md={12}> 
                    <h5>Le tue spese: {totalespese}€</h5>
                    {NewGraphicBar(spese)}

                </Col> 
                <Col xs={12} md={12}>
                    <h5>I tuoi guadagni: {totaleguadagno}€</h5>
                    {NewGraphicBar(dataguadagno)}
                </Col>
                {filtro === null ?                
                <Col xs={12}>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Mese/Anno</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(() => {
                                const arr = [];
                                listamesi.map((item) => {
                                    return arr.push(<tr className='monthList' onClick={() => handleFilter({item})}><td>{item}</td></tr>);
                                })
                                return arr;
                            })()}
                        </tbody>
                    </Table>
                </Col> : null }
                {listaspese.length ? <>
                    <Col xs={12}>
                    <h1>Spese</h1>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Riferimento</th>
                                <th>Note</th>
                                <th>Costo</th>
                                <th>Data</th>
                                <th>Stato</th>
                                <th>Gestisci</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(() => {
                                const arr = [];
                                let conto = 0;
                                listaspese.map((item) => {
                                    if(filtro !== null) {
                                        if(item.DataInserimento.search(filtro) > -1) {
                                            if(!item.IsGuadagno) {
                                                conto++;
                                                return arr.push(<tr>
                                                    <td>{conto}</td>
                                                    <td>{item.Motivo}</td>
                                                    <td>{item.Note}</td>
                                                    <td>{item.Costo}€</td>
                                                    <td>{YMD(item.DataInserimento)}</td>
                                                    <td style={{color: (item.Pagato) ? 'green' : 'red', fontWeight: 'bold'}}>{item.Pagato ? 'Pagato' : 'Non pagata'}</td>
                                                    <td><Gear className='settings' onClick={() => handleSpesa(item.ID)}/></td>
                                                </tr>)
                                            }
                                        } 
                                    } else {
                                        conto++;
                                        if(!item.IsGuadagno) {
                                            return arr.push(<tr>
                                                <td>{conto}</td>
                                                <td>{item.Motivo}</td>
                                                <td>{item.Note}</td>
                                                <td>{item.Costo}€</td>
                                                <td>{YMD(item.DataInserimento)}</td>
                                                <td style={{color: (item.Pagato) ? 'green' : 'red', fontWeight: 'bold'}}>{item.Pagato ? 'Pagato' : 'Non pagata'}</td>
                                                <td><Gear className='settings' onClick={() => handleSpesa(item.ID)}/></td>
                                            </tr>) 
                                        }
                                    }
                                })
                                return arr;
                            })()}
                        </tbody>
                </Table> </Col> </> : null}
                {listaguadagnofromproprieta.length ? <>
                <Col xs={12}>
                    <h1>Guadagni da servizi</h1>
                    {NewGraphicBar(speseHome)}
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Riferimento</th>
                                <th>Motivo</th>
                                <th>Prezzo</th>
                                <th>Quantità</th>
                                <th>Data</th>
                                <th>Gestisci</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(() => {
                                const arr = [];
                                let conto = 0;
                                listaguadagnofromproprieta.map((item) => {
                                    if(isValidPren(item.RifPrenotazione, listaprenotazioni)) {
                                        if(filtro !== null) {
                                            if(filtraproprieta === false) {
                                                if(item.DataInserimento.search(filtro) > -1) {
                                                    if(item.IsGuadagno) {
                                                        conto++;
                                                        return arr.push(<tr>
                                                            <td>{conto}</td>
                                                            <td>Pren. #{item.RifPrenotazione} - {nomeCasa(item.RifProprieta, listaproprieta)}</td>
                                                            <td>{item.Motivo}</td>
                                                            <td>{item.Motivo === "Tasse Soggiorno" ? "-" : null}{item.Costo}€</td>
                                                            <td>{item.Quantita}</td>
                                                            <td>{YMD(item.DataInserimento)}</td>
                                                            <td><Gear className='settings' onClick={() => handleSettings(item.RifPrenotazione)}/></td>
                                                        </tr>)
                                                    }
                                                }
                                            } else {
                                                if(filtraproprieta === item.RifProprieta) {
                                                    if(item.DataInserimento.search(filtro) > -1) {
                                                        if(item.IsGuadagno) {
                                                            conto++;
                                                            return arr.push(<tr>
                                                                <td>{conto}</td>
                                                                <td>Pren. #{item.RifPrenotazione} - {nomeCasa(item.RifProprieta, listaproprieta)}</td>
                                                                <td>{item.Motivo}</td>
                                                                <td>{item.Motivo === "Tasse Soggiorno" ? "-" : null}{item.Costo}€</td>
                                                                <td>{item.Quantita}</td>
                                                                <td>{YMD(item.DataInserimento)}</td>
                                                                <td><Gear className='settings' onClick={() => handleSettings(item.RifPrenotazione)}/></td>
                                                            </tr>)
                                                        }
                                                    }  
                                                }
                                            }
                                        } else {
                                            if(filtraproprieta === false) {
                                                if(item.IsGuadagno) {
                                                    conto++;
                                                    return arr.push(<tr>
                                                        <td>{conto}</td>
                                                        <td>Pren. #{item.RifPrenotazione} - {nomeCasa(item.RifProprieta, listaproprieta)}</td>
                                                        <td>{item.Motivo}</td>
                                                        <td>{item.Motivo === "Tasse Soggiorno" ? "-" : null}{item.Costo}€</td>
                                                        <td>{item.Quantita}</td>
                                                        <td>{YMD(item.DataInserimento)}</td>
                                                        <td><Gear className='settings' onClick={() => handleSettings(item.RifPrenotazione)}/></td>
                                                    </tr>)    
                                                }    
                                            } else {
                                                if(filtraproprieta === item.RifProprieta) {
                                                    if(item.IsGuadagno) {
                                                        conto++;
                                                        return arr.push(<tr>
                                                            <td>{conto}</td>
                                                            <td>Pren. #{item.RifPrenotazione} - {nomeCasa(item.RifProprieta, listaproprieta)}</td>
                                                            <td>{item.Motivo}</td>
                                                            <td>{item.Motivo === "Tasse Soggiorno" ? "-" : null}{item.Costo}€</td>
                                                            <td>{item.Quantita}</td>
                                                            <td>{YMD(item.DataInserimento)}</td>
                                                            <td><Gear className='settings' onClick={() => handleSettings(item.RifPrenotazione)}/></td>
                                                        </tr>)    
                                                    }    
                                                }
                                            }   
                                        }
                                    }
                                })
                                return arr;
                            })()}
                        </tbody>
                </Table> </Col> </>: null}
                {listaguadagno.length ? <>
                    <h1>Guadagni extra</h1>
                    <Col xs={12}>
                    <Table ref={myRef} responsive>
                        <thead>
                            <tr>
                                {!raggruppa ? <th>#</th> : <th>Seleziona</th>}
                                <th>Riferimento</th>
                                <th>Motivo</th>
                                <th>Prezzo</th>
                                <th>Data</th>
                                <th>Rif. Proprietà</th>
                                <th>Stato</th>
                                <th>Gestisci</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(() => {
                                const arr = [];
                                let conto = 0;
                                listaguadagno.map((item) => {
                                    if(filtro !== null) {
                                        if(filtraproprieta === false) {
                                            if(item.DataInserimento.search(filtro) > -1) {
                                                conto++;
                                                return arr.push(<tr>
                                                    <td>{conto}</td>
                                                    <td>{item.Motivo}</td>
                                                    <td>{item.Riferimento}</td>
                                                    <td>{item.Costo}€</td>
                                                    <td>{YMD(item.DataInserimento)}</td>
                                                    <td>{item.Raggruppato > 0 ? nomeCasa(item.Raggruppato, listaproprieta) : 'No'}</td>
                                                    <td style={{color: (item.Pagato) ? 'green' : 'red', fontWeight: 'bold'}}>{item.Pagato ? 'Pagato' : 'Non pagata'}</td>
                                                    <td><Gear className='settings' onClick={() => handleModGuadagno(item.ID)}/></td>
                                                </tr>)
                                            }      
                                        } else {
                                            if(filtraproprieta === item.Raggruppato) {
                                                if(item.DataInserimento.search(filtro) > -1) {
                                                    conto++;
                                                    return arr.push(<tr>
                                                        <td>{conto}</td>
                                                        <td>{item.Motivo}</td>
                                                        <td>{item.Riferimento}</td>
                                                        <td>{item.Costo}€</td>
                                                        <td>{YMD(item.DataInserimento)}</td>
                                                        <td>{item.Raggruppato > 0 ? nomeCasa(item.Raggruppato, listaproprieta) : 'No'}</td>
                                                        <td style={{color: (item.Pagato) ? 'green' : 'red', fontWeight: 'bold'}}>{item.Pagato ? 'Pagato' : 'Non pagata'}</td>
                                                        <td><Gear className='settings' onClick={() => handleModGuadagno(item.ID)}/></td>
                                                    </tr>)
                                                }      
                                            }
                                        }   
                                    } else {
                                        if(filtraproprieta === false) {
                                            conto++;
                                            return arr.push(<tr>
                                                <td>{conto}</td>
                                                <td>{item.Motivo}</td>
                                                <td>{item.Riferimento}</td>
                                                <td>{item.Costo}€</td>
                                                <td>{YMD(item.DataInserimento)}</td>
                                                <td>{item.Raggruppato > 0 ? nomeCasa(item.Raggruppato, listaproprieta) : 'No'}</td>
                                                <td style={{color: (item.Pagato) ? 'green' : 'red', fontWeight: 'bold'}}>{item.Pagato ? 'Pagato' : 'Non pagata'}</td>
                                                <td><Gear className='settings' onClick={() => handleModGuadagno(item.ID)}/></td>
                                            </tr>)          
                                        } else {
                                            if(filtraproprieta === item.Raggruppato) {
                                                conto++;
                                                return arr.push(<tr>
                                                    <td>{conto}</td>
                                                    <td>{item.Motivo}</td>
                                                    <td>{item.Riferimento}</td>
                                                    <td>{item.Costo}€</td>
                                                    <td>{YMD(item.DataInserimento)}</td>
                                                    <td>{item.Raggruppato > 0 ? nomeCasa(item.Raggruppato, listaproprieta) : 'No'}</td>
                                                    <td style={{color: (item.Pagato) ? 'green' : 'red', fontWeight: 'bold'}}>{item.Pagato ? 'Pagato' : 'Non pagata'}</td>
                                                    <td><Gear className='settings' onClick={() => handleModGuadagno(item.ID)}/></td>
                                                </tr>)  
                                            }
                                        }                    
                                    }               
                                })
                                return arr;
                            })()}
                        </tbody>
                </Table> </Col> </>: null}
                {listaprenotazioni.length ? <>
                    <Col xs={12}>
                    <h1>Introiti da case (%)</h1>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Riferimento</th>
                                <th>Guadagno</th>
                                <th>Percentuale(%)</th>
                                <th>Data CheckOut</th>
                                <th>Gestisci</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(() => {
                                const arr = [];
                                let conto = 0;
                                listaprenotazioni.map((item) => {
                                    if(getPercentualeCasa(item.RifProprieta, listaproprieta) > 0 && item.GuadagnoDisabilitato === 0) {
                                        if(filtro !== null) {
                                            if(filtraproprieta === false) {
                                                if(item.DataOut.search(filtro) > -1) {
                                                    if(item.Pagato) {
                                                        conto++;
                                                        return arr.push(<tr>
                                                            <td>{conto}</td>
                                                            <td>Pren. #{item.ID} - {nomeCasa(item.RifProprieta, listaproprieta)}</td>
                                                            <td>{parseFloat(calcolaGuadagnoCasa(item.RifProprieta, listaprenotazioni, item.ID, listaproprieta, item.Prezzo).toFixed(2))}€</td>
                                                            <td>{getPercentualeCasa(item.RifProprieta, listaproprieta)}%</td>
                                                            <td>{YMD(item.DataOut)}</td>
                                                            <td><Gear className='settings' onClick={() => handleSettings(item.ID)}/></td>
                                                        </tr>)
                                                    }
                                                }
                                            } else {
                                                if(filtraproprieta === item.RifProprieta) {
                                                    if(item.DataOut.search(filtro) > -1) {
                                                        if(item.Pagato) {
                                                            conto++;
                                                            return arr.push(<tr>
                                                                <td>{conto}</td>
                                                                <td>Pren. #{item.ID} - {nomeCasa(item.RifProprieta, listaproprieta)}</td>
                                                                <td>{parseFloat(calcolaGuadagnoCasa(item.RifProprieta, listaprenotazioni, item.ID, listaproprieta, item.Prezzo).toFixed(2))}€</td>
                                                                <td>{getPercentualeCasa(item.RifProprieta, listaproprieta)}%</td>
                                                                <td>{YMD(item.DataOut)}</td>
                                                                <td><Gear className='settings' onClick={() => handleSettings(item.ID)}/></td>
                                                            </tr>)
                                                        }
                                                    }
                                                }
                                            }
                                        } else {
                                            if(filtraproprieta === false) {
                                                if(item.Pagato) {
                                                    conto++;
                                                    return arr.push(<tr>
                                                        <td>{conto}</td>
                                                        <td>Pren. #{item.ID} - {nomeCasa(item.RifProprieta, listaproprieta)}</td>
                                                        <td>{parseFloat(calcolaGuadagnoCasa(item.RifProprieta, listaprenotazioni, item.ID, listaproprieta, item.Prezzo).toFixed(2))}€</td>
                                                        <td>{getPercentualeCasa(item.RifProprieta, listaproprieta)}%</td>
                                                        <td>{YMD(item.DataOut)}</td>
                                                        <td><Gear className='settings' onClick={() => handleSettings(item.ID)}/></td>
                                                    </tr>) 
                                                }       
                                            } else {
                                                if(filtraproprieta === item.RifProprieta) {
                                                    if(item.Pagato) {
                                                        conto++;
                                                        return arr.push(<tr>
                                                            <td>{conto}</td>
                                                            <td>Pren. #{item.ID} - {nomeCasa(item.RifProprieta, listaproprieta)}</td>
                                                            <td>{parseFloat(calcolaGuadagnoCasa(item.RifProprieta, listaprenotazioni, item.ID, listaproprieta, item.Prezzo).toFixed(2))}€</td>
                                                            <td>{getPercentualeCasa(item.RifProprieta, listaproprieta)}%</td>
                                                            <td>{YMD(item.DataOut)}</td>
                                                            <td><Gear className='settings' onClick={() => handleSettings(item.ID)}/></td>
                                                        </tr>) 
                                                    }
                                                }
                                            }             
                                        }
                                    }
                                })
                                return arr;
                            })()}
                        </tbody>
                </Table> </Col> </>: null}
                </>}
            </Row>
        </Container>
        <Modal
            show={modalSpese}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => showModal(false)}
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Inserisci spesa
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Spese</h4>
                <Container>
                    <Row>
                        <Col style={{margin:'0.5rem'}} xs={5}>
                            <FloatingLabel label={"Riferimento spesa..."}>
                                <Form.Select onChange={(e) => setNewSpesa({...newspesa, Nome: e.target.value})}>
                                    <option value="Dipendenti">Dipendenti</option>
                                    <option value="Acquaviva">Acquaviva</option>
                                    <option value="Manutenzione">Manutenzione</option>
                                    <option value="Nonsolocaffè">Nonsolocaffè</option>
                                    <option value="Lavanderia">Lavanderia</option>
                                    <option value="Luce">Luce</option>
                                    <option value="F24">F24</option>
                                    <option value="Condominio">Condominio</option>
                                    <option value="Affitti">Affitti</option>
                                    <option value="AI.RÒ">AI.RÒ</option>
                                    <option value="Supermercato">Supermercato</option>
                                    <option value="Altro">Altro</option>
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin:'0.5rem'}} xs={5}>
                            <FloatingLabel label="€">
                            <Form.Control
                                type="number"
                                placeholder="€"
                                onChange={(e) => handleNumber(e, 'spesa')}
                            />
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin: '0.5rem'}} xs={10}>
                            <FloatingLabel label="Note">
                                <Form.Control as="textarea"
                                    placeholder="Note"
                                    onChange={(e) => setNewSpesa({...newspesa, Note: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin: '0.5rem'}} xs={10}>
                            <Form.Check
                                onChange={(e) => setNewSpesa({...newspesa, Pagato: e.target.checked})}
                                label="Pagato?"
                            />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={disabled} variant="success" onClick={handleSaveSpese}>Salva</Button>
                <Button variant="secondary" onClick={() => showModal(false)}>Chiudi</Button>
            </Modal.Footer>
        </Modal>        
        <Modal
            show={modalHandleSpesa}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => showModalHandleSpesa(false)}
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Modifica spesa
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Spesa</h4>
                <Container>
                    <Row>
                        <Col style={{margin:'0.5rem'}} xs={5}>
                            <FloatingLabel label={"Riferimento spesa..."}>
                                <Form.Select value={handleItem?.Motivo} onChange={(e) => setHandleItem({...handleItem, Motivo: e.target.value})}>
                                    <option value="Dipendenti">Dipendenti</option>
                                    <option value="Acquaviva">Acquaviva</option>
                                    <option value="Manutenzione">Manutenzione</option>
                                    <option value="Nonsolocaffè">Nonsolocaffè</option>
                                    <option value="Lavanderia del Golfo">Lavanderia del Golfo</option>
                                    <option value="AI.RÒ">AI.RÒ</option>
                                    <option value="Supermercato">Supermercato</option>
                                    <option value="Altro">Altro</option>
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin:'0.5rem'}} xs={5}>
                            <FloatingLabel label="€">
                            <Form.Control
                                value={handleItem?.Costo}
                                type="number"
                                placeholder="€"
                                onChange={(e) => handleNumber(e, 'modifyspesa')}
                            />
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin: '0.5rem'}} xs={10}>
                            <FloatingLabel label="Note">
                                <Form.Control as="textarea"
                                    value={handleItem?.Note}
                                    placeholder="Note"
                                    onChange={(e) => setHandleItem({...handleItem, Note: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{margin: '0.5rem'}} xs={10}>
                            <Form.Check
                                checked={handleItem?.Pagato}
                                onChange={(e) => setHandleItem({...handleItem, Pagato: e.target.checked})}
                                label="Pagato?"
                            />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={disabled} variant="danger" onClick={handleDeleteSpesa}>Elimina</Button>
                <Button disabled={disabled} variant="success" onClick={handleModifySpesa}>Salva</Button>
                <Button variant="secondary" onClick={() => showModalHandleSpesa(false)}>Chiudi</Button>
            </Modal.Footer>
        </Modal>        
        <Modal
        show={modalGuadagno}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => showModalGuadagno(false)}
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Inserisci guadagno
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4>Guadagno</h4>
            <Container>
                <Row>
                    <Col style={{margin:'0.5rem'}} xs={5}>
                        <FloatingLabel label={"Riferimento guadagno..."}>
                            <Form.Select onChange={(e) => {setNewGuadagno({...newguadagno, Nome: e.target.value})}}>
                                <option value="Colazione">Colazione</option>
                                <option value="Generico">Generico</option>
                                <option value="Frigobar">Frigobar</option>
                                <option value="Biancheria Extra">Biancheria Extra</option>
                                <option value="Check-In">Check-In</option>
                                <option value="Pulizia Extra">Pulizia Extra</option>
                                <option value="Culla">Culla</option>
                                <option value="Seggiolini">Seggiolini</option>
                                <option value="Altro">Altro</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                    <Col style={{margin:'0.5rem'}} xs={5}>
                        <FloatingLabel label="€">
                        <Form.Control
                            type="number"
                            placeholder="€"
                            onChange={(e) => handleNumber(e, 'guadagno')}
                        />
                        </FloatingLabel>
                    </Col>
                    <Col style={{margin:'0.5rem'}} xs={10}>
                        <FloatingLabel label="Note">
                        <Form.Control as="textarea"
                            placeholder="Note"
                            onChange={(e) => setNewGuadagno({...newguadagno, Riferimento: e.target.value})}
                        />
                        </FloatingLabel>
                    </Col>
                    <Col style={{margin: '0.5rem'}} xs={10}>
                        <FloatingLabel label="Proprietà">
                            <Form.Select onChange={(e) => setNewGuadagno({...newguadagno, Raggruppato: e.target.value})}>
                                <option value="0">Nessuno</option>
                                {listaproprieta.map((item) => {
                                    return(<option value={item.ID}>{item.Nome}</option>)
                                })}
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                </Row>
            </Container>
        </Modal.Body>
        <Modal.Footer>
            <Button disabled={disabled} variant="success" onClick={handleSaveGuadagno}>Salva</Button>
            <Button variant="secondary" onClick={() => showModalGuadagno(false)}>Chiudi</Button>
        </Modal.Footer>
    </Modal>      
    <Modal
        show={modalHandleGuadagno}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => showModalHandleGuadagno(false)}
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Modifica guadagno
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4>Guadagno</h4>
            <Container>
                <Row>
                    <Col style={{margin:'0.5rem'}} xs={5}>
                        <FloatingLabel label={"Riferimento guadagno..."}>
                            <Form.Select value={handleGuadagno.Motivo} onChange={(e) => {setHandleGuadagno({...handleGuadagno, Motivo: e.target.value})}}>
                                <option value="Colazione">Colazione</option>
                                <option value="Noleggio E-Bike">Noleggio E-Bike</option>
                                <option value="Generico">Generico</option>
                                <option value="Altro">Altro</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                    <Col style={{margin:'0.5rem'}} xs={5}>
                        <FloatingLabel label="€">
                        <Form.Control
                            value={handleGuadagno.Costo}
                            type="number"
                            placeholder="€"
                            onChange={(e) => handleNumber(e, 'handleguadagno')}
                        />
                        </FloatingLabel>
                    </Col>
                    <Col style={{margin:'0.5rem'}} xs={10}>
                        <FloatingLabel label="Note">
                        <Form.Control as="textarea"
                            value={handleGuadagno.Riferimento}
                            placeholder="Note"
                            onChange={(e) => setHandleGuadagno({...handleGuadagno, Riferimento: e.target.value})}
                        />
                        </FloatingLabel>
                    </Col>
                    <Col style={{margin: '0.5rem'}} xs={10}>
                        <FloatingLabel label="Raggruppamento">
                            <Form.Select value={handleGuadagno.Raggruppato} onChange={(e) => setHandleGuadagno({...handleGuadagno, Raggruppato: e.target.value})}>
                                <option value="0">Nessuno</option>
                                {listaraggruppamenti.map((item) => {
                                    <option value={item.ID}>{item.Nome}</option>
                                })}
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                </Row>
            </Container>
        </Modal.Body>
        <Modal.Footer>
            <Button disabled={disabled} variant="danger" onClick={handleDeleteGuadagno}>Elimina</Button>
            <Button disabled={disabled} variant="success" onClick={handleModifyGuadagno}>Salva</Button>
            <Button variant="secondary" onClick={() => showModalHandleGuadagno(false)}>Chiudi</Button>
        </Modal.Footer>
    </Modal> 
    <Modal
        show={modalHandleRaggruppa}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => showModalHandleRaggruppa(false)}
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Filtra
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4>Filtra per prorietà</h4>
            <Form.Select value={filtraproprieta} onChange={(e) => setFiltraProprieta(parseInt(e.target.value))}>
                <option value={false}>Seleziona...</option>
                {listaproprieta.map((item) => {
                    return(
                        <option value={item.ID}>{item.Nome}</option>
                    )
                })}
            </Form.Select>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => showModalHandleRaggruppa(false)}>Chiudi</Button>
        </Modal.Footer>
    </Modal>  
    </>
    )
}

export default Contabilita;