import React, {useState, useEffect} from 'react';
import { makeRequest } from '../Global/makeRequest';
import { Button, FloatingLabel, Form, Col, Image} from 'react-bootstrap';
import { XLg, CircleFill, Gear } from 'react-bootstrap-icons';
import toast, {Toaster} from 'react-hot-toast';
import SettingHome from '../Proprieta/SettingHome';
import { isvalidemail } from '../Global/Functions';
function SettingUser({current, user, close}) {
    const [infoUser, setInfoUser] = useState([]);
    const [disabled, isDisabled] = useState(false);
    const [updated, setUpdate] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [listaproprieta, setListaProprieta] = useState([]);
    const [proprieta, setProprieta] = useState([]);
    const [listaprenotazioni, setListaPrenotazioni] = useState([]);
    const [handleNew, setHandleNew] = useState(true);
    const [selectedHome, setSelectedHome] = useState(0);
    const handleClickSetting = (id) => {
        setSelectedHome(id);
    }
    const handleClick = () => {
        isDisabled(true);
        if(username.length > 3) {
            if(password.length > 3 || password.length === 0) {
                if(isvalidemail(email) || !email.length) { 
                    const admin = infoUser.Admin;
                    const id = infoUser.ID;
                    let str = '/user/modify';
                    if(!password.length)
                        str = '/user/modifywithout';
                    makeRequest.put(str,{username, password, email, admin, id})
                    .then(res=> {
                        toast.success('Account modificato con successo')
                        setTimeout(()=>{
                            close(0);
                        },2000)
                    })
                    .catch(err=> {
                        console.log(err);
                        toast.error(err.response?.data);
                        isDisabled(false);
                    })
                } else {
                    toast.error("Inserisci una email valida");
                    isDisabled(false);
                }
            } else {
                toast.error("Inserisci una password valida: minimo 4 caratteri.");
                isDisabled(false);
            }
        } else {
            toast.error("Inserisci un username valido: minimo 4 caratteri.");
            isDisabled(false);
        }
    }
    useEffect(() => {
        makeRequest.post('/user/getuser',{user})
        .then(res=> {
            setUsername(res.data[0].username);
            setInfoUser(res.data[0]);
            setEmail(res.data[0].email);
        })
        .catch(err=>{
            toast.error(err.response?.data);
        })
        const getProprieta = async() => {
            const proprietari = user;
            await makeRequest.post('/proprieta/get',{proprietari})
            .then(res=>{
                console.log(res.data);
                setListaProprieta(res.data)
                const newArr = res.data.map((item) => {
                    return item.ID;
                })
                setProprieta(newArr);
                console.log(newArr, 'Proprieta ID');
            })
            .catch(err=>console.log(err))
        }
        let val = undefined;
        try {
            getProprieta()
        }
        catch {
            val = undefined;
        }
        console.log(val, 'listaprop');
    },[user, updated])
    useEffect(() => {
        if(selectedHome === 0)
            setUpdate(!updated);
    },selectedHome)
    useEffect(() => {
        if(handleNew) {
            makeRequest.post('/prenotazioni/getfromhouse', {proprieta})
            .then(res=> {
                setListaPrenotazioni(res.data); 
                console.log('prenotazioni', res.data);
            })
        } else {
            setHandleNew(true);
        }
    },[proprieta])
    useEffect(() => {
        setHandleNew(false);
        let newArr = listaproprieta.map((item) => {
            return {...item, Occupata: false}
        })
        newArr = newArr.map((item) => {
            for(var i = 0; i < listaprenotazioni.length; i++) {
                if(item.ID === listaprenotazioni[i].RifProprieta) {
                    return {...item, Occupata: true}
                }
            }
            return item;
        })
        setListaProprieta(newArr);
    },[listaprenotazioni])
    return (
        <>
        <Toaster/>
        {selectedHome ? 
            <SettingHome user={current} home={selectedHome} close={setSelectedHome}/>
        :
        <>
        <Col xs={12}>
        <Button onClick={() => close(0)} className='closebutton' variant="light"><XLg/></Button> <h1>{infoUser.username}</h1>
        <FloatingLabel label="Username">
            <Form.Control
                type="text"
                placeholder="Username"
                value={username}
                className='mb-3'
                onChange={(e) => { const value = e.target.value; setUsername(value)}}
            />        
        </FloatingLabel>
        <FloatingLabel label="Password">
            <Form.Control
                type="password"
                placeholder='Password'
                className='mb-3'
                onChange={(e) => { const value = e.target.value; setPassword(value)}}
            />
        </FloatingLabel>
        <FloatingLabel label="Email">
            <Form.Control
                type="email"
                placeholder='Email...'
                value={email}
                className='mb-3'
                onChange={(e) => { const value = e.target.value; setEmail(value)}}
            />
        </FloatingLabel>
        <Button className='mb-3' disabled={disabled} onClick={handleClick} variant="secondary">Salva Account</Button>
        {listaproprieta.length ?
            <>
            <h3>Le sue proprietà</h3>
                {(() => {
                        const arr = [];
                        let conto = 0;
                        listaproprieta.map((item) => {
                            return arr.push(
                                <Col style={{padding: '10px', border: '0.2px solid gray', borderTop: 'none', borderRight: 'none', borderRadius: '5px'}} xs={10} md={3}>
                                    <div className="d-flex">
                                        <div>
                                            <p><Gear style={{margin: '0.2rem'}} onClick={() => handleClickSetting(item.ID)} className='settings'/></p>
                                            <p><CircleFill style={{margin: '1rem'}} className={item.Occupata ? 'greenlight' : 'redlight'}/></p>
                                        </div>
                                        <div className="d-flex">
                                            <Image
                                                src={item.Link}
                                                width="200"
                                                height="200"
                                            />
                                            <div style={{marginLeft: '0.5rem'}}>
                                                <h5 style={{verticalAlign: 'middle', marginTop: '2rem'}}>{item.Nome}</h5>
                                                <p>{item.Citta}</p>
                                                <p>{item.Via}</p>
                                                <p>Posti letto: {item.Posti}</p>
                                                {//item.Occupata ? <p>Check-Out: {item.DataOut</p>}
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                        return arr;
                    })()}  
            </>
        : null}
        </Col>
        </>}
        </>
    )
}

export default SettingUser;