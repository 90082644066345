import React, {useEffect, useRef, useState } from 'react';
import { Col, Button, Form, FloatingLabel, Modal, Container, Row, Table} from 'react-bootstrap';
import { CheckLg, XLg } from 'react-bootstrap-icons';
import { makeRequest } from '../Global/makeRequest';
import toast from 'react-hot-toast';
//npimport PieActiveArc from '../Global/Chart';
import { getInvertiHome, getPercentualeWithout, validateHhMm } from '../Global/Functions';
import NewGraphic from '../Global/PieChart';
function SettingPren({usern, pren, close}) {
    const YMD = (date) => {
        const val = date?.split('-');
        return val[2]+'-'+val[1]+'-'+val[0];
    }
    const [cedolarevalue, setCedolare] = useState(0.0);
    const [updatespese, setUpdate] = useState(false);
    const [guadagniPren, setGuadagniPren] = useState([]);
    const [listaacconti, setListaAcconti] = useState(false);
    const firstRender = useRef(true);
    const secondRender = useRef(true);
    const dataoggi = new Date();
    const datadomani = new Date(dataoggi.getTime());
    datadomani.setDate(dataoggi.getDate() + 1);
    
    const datachart = [
        { id: 0, value: 0, label: 'Pulizie' },
        { id: 1, value: 0, label: 'CheckIn/Out' },
        { id: 2, value: 0, label: 'CheckIn/Out Tardivo' },
        { id: 3, value: 0, label: 'Biancheria' },
        { id: 4, value: 0, label: 'Culla' },
        { id: 5, value: 0, label: 'Seggiolone' },
        { id: 6, value: 0, label: 'Bagnetto' },
        { id: 7, value: 0, label: 'Tasse Soggiorno' },        
        { id: 9, value: 0, label: 'Quota HostSicilia'},
        { id: 10, value: 0, label: 'Quota Cliente'},
    ]
    const handleNumber = (e, tipo) => {
        let value = e.target.value;
        if(value < 0) {
            value = 0;
        }
        switch(tipo) {
            case "Adulti": {
                setModifyPren({...modifypren, Adulti: value});
                break;
            }
            case "Bambini": {
                setModifyPren({...modifypren, Bambini: value});
                break;
            }
            case "Neonati": {
                setModifyPren({...modifypren, Neonati: value});
                break;
            }
            case "Seggiolone": {
                setModifyPren({...modifypren, Seggiolone: value});
                break;           
            }
            case "Culla": {
                setModifyPren({...modifypren, Culla: value});
                break;           
            }
            case "Bagnetto": {
                setModifyPren({...modifypren, Bagnetto: value});
                break;           
            }
            case "Prezzo": {
                setModifyPren({...modifypren, Prezzo: value});
                break;     
            }
            case "Percentuale": {
                setModifyPren({...modifypren, PercentualeOTA: value});
                break;          
            }
            case "PuliziaPrice": {
                setNewSpesa({...newspesa, puliziaprice: value})
                break;
            }
            case "CheckInPrice": {
                setNewSpesa({...newspesa, checkinprice: value})
                break;
            }
            case "BiancheriaPrice": {
                setNewSpesa({...newspesa, biancheriaprice: value})
                break;
            }
            case "CullaPrice": {
                setNewSpesa({...newspesa, cullaprice: value})
                break;
            }
            case "BagnettoPrice": {
                setNewSpesa({...newspesa, bagnettoprice: value})
                break;
            }
            case "SeggiolonePrice": {
                setNewSpesa({...newspesa, seggioloneprice: value})
                break;
            }
            case "acconto": {
                setNewAcconto({...newacconto, Prezzo: value})
                break;
            }
        }
    }
    const handleClickDelete = (e) => {
        const id = e;
        if(window.confirm("Sei sicuro di voler eliminare questa riga?")) {
            makeRequest.post('/spese/remove',{id})
            .then(res=> {
                toast.success("Riga eliminata con successo")
                setUpdate(!updatespese)
            })
            .catch(err=> toast.error("Si è verificato un errore"))
        }
    }
    const handleClickPulizia = () => { 
        if(selectedpulizia) {
            setSelected(false);
            setNewSpesa({...newspesa, pulizia: 0})
        } else {
            setSelected(true);
            setNewSpesa({...newspesa, pulizia: 50})           
        }
    }
    const handleClickCheck = () => { 
        if(selectedcheckin) {
            setSelectedCheck(false);
            setNewSpesa({...newspesa, checkin: 0})
        } else {
            setSelectedCheck(true);
            setNewSpesa({...newspesa, checkin: 30})           
        }
    }
    const calcoloTassa = () => {
        let val = 0.0;
        if(tassa.persone > 0) {
            if(tassa.notti > 0 ) {
                if(tassa.prezzo > 0) {
                    val = parseFloat(tassa.persone*tassa.notti*tassa.prezzo).toFixed(2);
                }
            }
        }
        return val;
    }
    const calcoloSpese = () => {}
    const [prenotazione, setPrenotazione] = useState([]);
    const [disabledButton, setDisabled] = useState(false);
    const [modifypren, setModifyPren] = useState([]);
    const [modalSpese, showModal] = useState(false);
    const [modalModify, showModalModify] = useState(false);
    const [modalAcconto, showModalAcconto] = useState(false);
    const [spese, setSpese] = useState(datachart);
    const [listaspese, setListaSpese] = useState([]);
    const [selectedpulizia, setSelected] = useState(false);
    const [selectedcheckin, setSelectedCheck] = useState(false);
    const [newspesa, setNewSpesa] = useState({
        biancheriaprice: 12, puliziaprice: 50, checkinprice: 30, cullaprice: 5, seggioloneprice: 3, bagnettoprice: 2
    })
    const [tassa, setTassa] = useState([
        {persone: 0, notti: 0, prezzo: 0.0}
    ])
    const arrayDate = (datain, dataout) => {
        const start = new Date(datain);
        const end = new Date(dataout);
        const days = Math.floor((end - start) / (1000 * 60 * 60 * 24));
        const dates = [];
        for (let i = 0; i < days; i++) {
            const currentDate = new Date(start.getTime() + i * 24 * 60 * 60 * 1000);
            dates.push(formatDate(currentDate));
        }
        return dates;
    }
    function compareTime(time1, time2) {
        return new Date(time1) > new Date(time2); // true if time1 is later
    }
    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${year}-${month}-${day}`;
    };
    const [load, isLoaded] = useState(false);
    const [spesatotale, setSpesaTotale] = useState(0.0);
    const getpersone = () => {
        let totale = 0;
        const arr = [];
        totale = prenotazione.Adulti+prenotazione.Bambini+prenotazione.Neonati;
        for(var i = 0; i < totale; i++) {
            arr.push(<option value={i+1}>{i+1} {i === 0 ? 'persona' : 'persone'}</option>);
        }
        return arr;
    }
    function dateDiffInDays(a, b) {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
      }
    const getnotti = () => {
        const dataIn = new Date(prenotazione.DataIn);
        const dataOut = new Date(prenotazione.DataOut);
        const totale = dateDiffInDays(dataIn, dataOut);
        const arr = [];
        for(var i = 0; i < totale; i++) {
            arr.push(<option value={i+1}>{i+1} {i === 0 ? 'notte' : 'notti'}</option>)
        }  
        return arr;
    }
    const handleDeletePren = () => {
        setDisabled(true);
        if(window.confirm("Sei sicuro di voler eliminare questa prenotazione? Non potrà essere recuperata")) {
            const id = prenotazione.ID;
            makeRequest.post('/prenotazioni/remove',{id})
            .then(res=>{
                toast.success("Prenotazione #"+id+" eliminata con successo")
                close(0);
            })
            .catch(err=>{
                toast.error("Si è verificato un errore, riprova più tardi");
            })
        }
        setDisabled(false);
    }
    const [newacconto, setNewAcconto] = useState({Prezzo: 0})
    const handleDeleteAcconto = (id) => {
        if(window.confirm("Sei sicuro di voler eliminare questo acconto?")) {
            makeRequest.post('/saldo/remove', {id})
            .then(res=> {
                toast.success("Acconto eliminato con successo");
                setUpdate(!updatespese)
            })
            .catch(err=> {
                toast.error(err.response?.data);
            })
        }
    }
    const handleSaveAcconto = () => {
        setDisabled(true)
        const soldi = newacconto.Prezzo;
        const proprieta = prenotazione.RifProprieta;
        if(soldi > 0) {
            makeRequest.put('/saldo/insert',{soldi,proprieta,pren})
            .then(res=>{
                toast.success("Acconto/Saldo inserito con successo")
                setUpdate(!updatespese)
                setNewAcconto({Prezzo: 0})
                showModalAcconto(false)
                setDisabled(false);
            })
            .catch(err=>{
                toast.error(err.response?.data)
                setDisabled(false);
            })
        } else {
            toast.error("Inserisci una quantità valida");
            setDisabled(false);
        }
    }
    const calcolaCedolare = async() => {
        let val = 0;
        if(prenotazione.Cedolare) {
            const guadagno = await getPercentualeWithout(prenotazione.RifProprieta);
            const prezzo = parseFloat(prenotazione.Prezzo.replace(',','.')).toFixed(2)
            const cedolare = parseFloat((prenotazione.PercentualeCedolare/100).toFixed(2));
            const hostsicilia = parseFloat((prezzo-prenotazione.PercentualeOTA)*(parseInt(guadagno)/100).toFixed(2))
        //let ota = prenotazione.PercentualeOTA;
            val = parseFloat(((prezzo-hostsicilia)*(cedolare)).toFixed(2));
            //console.log('cedolare', cedolare);
        }
        setCedolare(val);
        return val;
    }
    const getPercentualeCliente = () => {
        let val = 0;
        spese.map((item) => {
            if(item.label === "Quota Cliente")
                val = item.value;
            return false;
        })
        console.log(parseFloat(val));
        return parseFloat(val).toFixed(2);
    }
    const getTassaSoggiorno = () => {
        let val = 0;
        spese.map((item) => {
            if(item.label === "Tasse Soggiorno")
                val = item.value;
            return false;
        })
        return parseFloat(val).toFixed(2);
    }
    const handleSavePren = () => {
        setDisabled(true);
        const nome = modifypren.Nome;
        const cognome = modifypren.Cognome;
        const nazionalita = modifypren.Nazionalita;
        const datain = modifypren.DataIn;
        const orain = modifypren.OraIn;
        const dataout = modifypren.DataOut;
        const oraout = modifypren.OraOut;
        const adulti = modifypren.Adulti;
        const bambini = modifypren.Bambini;
        const neonati = modifypren.Neonati; 
        if(parseInt(adulti)+parseInt(bambini)+parseInt(neonati) === 0) {
            toast.error("Inserisci almeno una persona nella prenotazione");
            return setDisabled(false);
        }
        const culla = modifypren.Culla;
        const seggiolone = modifypren.Seggiolone;
        const bagnetto = modifypren.Bagnetto;
        const note = modifypren.Note;
        const cellulare = modifypren.Cellulare;
        const ota = modifypren.OTA;
        const cedolare = modifypren.Cedolare;
        const percentualecedolare = modifypren.PercentualeCedolare;
        const pagato = modifypren.Pagato;
        const admin = modifypren.Admin;
        let percentualeota = String(modifypren.PercentualeOTA)
        let prezzo = String(modifypren.Prezzo);
        if(prezzo.indexOf(',') > -1) {
            prezzo = prezzo.replace(',','.');
            prezzo = parseFloat(prezzo).toFixed(2);
        } else
            prezzo = parseFloat(prezzo).toFixed(2)
        if(percentualeota.indexOf(',') > -1) {
            percentualeota = parseFloat(percentualeota.replace(',','.')).toFixed(2)
        } else
            percentualeota = parseFloat(percentualeota.replace(',','.')).toFixed(2)
        const colazione = modifypren.Colazione;
        const guadagnodisabilitato = modifypren.GuadagnoDisabilitato;
        const id = modifypren.ID;
        const proprieta = prenotazione.RifProprieta;
        const colazioneextra = modifypren.ColazioneExtra;
        const biancheriaextra = modifypren.BiancheriaExtra;
        const puliziaextra = modifypren.PuliziaExtra;
        const checkinextra = modifypren.CheckInExtra;
        if(nome.length > 1) {
            if(cognome.length > 1) {
                if(nazionalita.length > 1) {
                    if(datain.length === 10) {
                        if(dataout.length === 10) { 
                            if(orain.length === 0 || validateHhMm(orain)) {
                                if(oraout.length === 0 || validateHhMm(oraout)) {
                                    if(compareTime(datain,dataout)) {
                                        toast.error("Inserisci una data di CheckIn antecedente alla data di CheckOut")
                                        return setDisabled(false);
                                    }
                                    if(cellulare.length > 8) {
                                        if(prezzo >= 0) {
                                            const arraydate = arrayDate(datain, dataout);
                                            makeRequest.put('/prenotazioni/modify',{arraydate, nome, cognome, nazionalita, datain, orain, dataout, oraout, adulti, bambini, neonati, culla, seggiolone, bagnetto, cellulare, note, prezzo, colazione, ota, cedolare, percentualecedolare, proprieta, percentualeota, guadagnodisabilitato, pagato, admin, id})
                                            .then(res=> {
                                                guadagniPren.map((item) => {
                                                    makeRequest.post('/guadagni/remove',{id: item.ID})
                                                })
                                                if(colazioneextra > 0) {
                                                    makeRequest.put('/guadagni/insertwithpren',{motivo: 'Colazione', riferimento: '', costo: colazioneextra, pagato: 1, raggruppato: proprieta, rifprenotazione: id})
                                                }
                                                if(biancheriaextra > 0) {
                                                    makeRequest.put('/guadagni/insertwithpren',{motivo: 'Biancheria Extra', riferimento: '', costo: biancheriaextra, pagato: 1, raggruppato: proprieta, rifprenotazione: id})
                                                }
                                                if(puliziaextra > 0) {
                                                    makeRequest.put('/guadagni/insertwithpren',{motivo: 'Pulizia Extra', riferimento: '', costo: puliziaextra, pagato: 1, raggruppato: proprieta, rifprenotazione: id})     
                                                }
                                                if(checkinextra > 0) {
                                                    makeRequest.put('/guadagni/insertwithpren',{motivo: 'CheckIn Extra', riferimento: '', costo: checkinextra, pagato: 1, raggruppato: proprieta, rifprenotazione: id})     
                                                }
                                                showModalModify(false);
                                                setPrenotazione(modifypren);
                                                toast.success("Prenotazione modificata con successo");
                                            })
                                            .catch(err=> {
                                                toast.error(err.response?.data);
                                            })
                                        } else {
                                            toast.error("Inserisci un prezzo valido");
                                        }
                                    } else {
                                        toast.error("Inserisci un orario di Check Out valido");
                                    }
                                } else {
                                    toast.error("Inserisci un orario di Check In valido");
                                }
                            } else {
                                toast.error("Inserisci un numero di cellulare valido");
                            }
                        } else {
                            toast.error("Inserisci una data di CheckOut valida");
                        }
                    } else {
                        toast.error("Inserisci una data di CheckIn valida");
                    }
                } else {
                    toast.error("Inserisci una Nazionalità valida ( min. 3 caratteri )");
                }
            } else {
                toast.error("Inserisci un Cognome valido ( min. 3 caratteri )");
            }
        } else {
            toast.error("Inserisci un Nome valido ( min. 3 caratteri )");
        }
        setDisabled(false);
    }
    const calcolaSpese = (arr) => {
        let val = 0;
        arr.map((item) => {
            if(item.Motivo !== "Tasse Soggiorno") {
                val = val+(item.Costo*item.Quantita);
            }
        })
        return parseFloat(val).toFixed(2);
    }
    const handleSaveSpese = () => {
        const tassa = calcoloTassa();
        setNewSpesa({...newspesa, tassa: parseFloat(tassa).toFixed(2)});
        const prenotazioneid = prenotazione.ID;
        const proprieta = prenotazione.RifProprieta;
        let isguadagno = 1;
        const pagato = 0;
        const note = '';
        if(newspesa?.tardivo) {
            const motivo = "CheckIn/Out Tardivo";
            const costo = newspesa.tardivo;
            const quantita = 1;
            isguadagno = 1;
            makeRequest.put('/spese/insert',{motivo, costo, pagato, isguadagno, quantita, note, prenotazioneid, proprieta})
        }
        if(newspesa?.biancheria) {
            const motivo = "Biancheria";
            const costo = newspesa.biancheriaprice;
            const quantita = newspesa.biancheria;
            isguadagno = 1;
            makeRequest.put('/spese/insert',{motivo, costo, pagato, isguadagno, quantita, note, prenotazioneid, proprieta})
        }
        if(newspesa?.seggiolone) {
            const motivo = "Seggiolone";
            const costo = newspesa.seggioloneprice;
            const quantita = newspesa.seggiolone;
            isguadagno = 1;
            makeRequest.put('/spese/insert',{motivo, costo, pagato, isguadagno, quantita, note, prenotazioneid, proprieta})
        }
        if(newspesa?.culla) {
            const motivo = "Culla";
            const costo = newspesa.cullaprice;
            const quantita = newspesa.culla;
            isguadagno = 1;
            makeRequest.put('/spese/insert',{motivo, costo, pagato, isguadagno, quantita, note, prenotazioneid, proprieta})
        }
        if(newspesa?.bagnetto) {
            const motivo = "Bagnetto";
            const costo = newspesa.bagnettoprice;
            const quantita = newspesa.bagnetto;
            isguadagno = 1;
            makeRequest.put('/spese/insert',{motivo, costo, pagato, isguadagno, quantita, note, prenotazioneid, proprieta})
        }
        if(tassa > 0) {
            const motivo = "Tasse Soggiorno";
            const costo = tassa;
            isguadagno = 0;
            const quantita = 1;
            makeRequest.put('/spese/insert',{motivo, costo, pagato, isguadagno, quantita, note, prenotazioneid, proprieta})
        }
        if(selectedcheckin) {
            const motivo = "CheckIn/Out";
            const costo = newspesa.checkinprice;
            const quantita = 1;
            isguadagno = 1;
            makeRequest.put('/spese/insert',{motivo, costo, pagato, isguadagno, quantita, note, prenotazioneid, proprieta})   
        }
        if(selectedpulizia) {
            const motivo = "Pulizie";
            const costo = newspesa.puliziaprice;
            const quantita = 1;
            isguadagno = 1;
            makeRequest.put('/spese/insert',{motivo, costo, pagato, isguadagno, quantita, note, prenotazioneid, proprieta})
        }
        setUpdate(!updatespese);
        toast.success('Spese inserite con successo');
        setNewSpesa({
            biancheriaprice: 12, puliziaprice: 50, checkinprice: 30, cullaprice: 5, seggioloneprice: 3, bagnettoprice: 2
        });
        setSelected(false);
        setSelectedCheck(false);
        showModal(false);
    }
    useEffect(() => {
        const id = pren;
        makeRequest.post('/prenotazioni/get',{id})
        .then(res=>{
            setModifyPren(res.data[0])
            setPrenotazione(res.data[0]);
            isLoaded(true);
        })
        .catch(err=>{
            toast.error("Si è verificato un errore");
            close(0);
        })
    },[pren])
    useEffect(() => {
        if(guadagniPren.length) {
            let colazioneextra = 0;
            let biancheriaextra = 0;
            let puliziaextra = 0;
            let checkinextra = 0;
            guadagniPren?.map((item) => {
                if(item.Motivo === "Colazione")
                    colazioneextra = item.Costo;
                else if(item.Motivo === "Biancheria Extra")
                    biancheriaextra = item.Costo;
                else if(item.Motivo === "Pulizia Extra")
                    puliziaextra = item.Costo;
                else if(item.Motivo === "CheckIn Extra")
                    checkinextra = item.Costo;
            })
            setModifyPren({...modifypren, ColazioneExtra: colazioneextra, BiancheriaExtra: biancheriaextra, PuliziaExtra: puliziaextra, CheckInExtra: checkinextra});
        } else {
            setModifyPren({...modifypren, ColazioneExtra: 0, BiancheriaExtra: 0, PuliziaExtra: 0, CheckInExtra: 0});
        }
    },[guadagniPren])
    useEffect(()=> {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        const varsupp = prenotazione;
        const proprieta = prenotazione.RifProprieta;
        const prenotazioni = pren;
        let totale = 0.0
        makeRequest.post('/guadagni/getpren', {pren})
        .then(res=> {
            setGuadagniPren(res.data);
        })
        makeRequest.post('/saldo/getsinglepren',{pren})
        .then(async result=>{
            const guadagni = result.data;
            setListaAcconti(result.data);
            const inverti = await getInvertiHome(proprieta);
            const guadagno = await getPercentualeWithout(proprieta);
            const cedoval = await calcolaCedolare();
            //console.log('guadagno qui è ', guadagno)
            //console.log('entro inverti', inverti, proprieta);
            const selectedyear = usern.YearSelected;
            makeRequest.post('/spese/get',{proprieta, prenotazioni, selectedyear})
            .then(results=> {
                const speseval = calcolaSpese(results.data);
                //console.log('entro request after inverti')
                //console.log('entro qui perchè resulto');
                //console.log(results.data);
                const newArr = spese.map((item) => {
                    let value = 0.0
                    for(var i = 0; i < results.data.length; i++) {
                        if(item.label === results.data[i].Motivo) {
                            if(item.label !== "Tasse Soggiorno") {
                                totale=parseFloat(totale.toFixed(2))+parseFloat(results.data[i].Costo.toFixed(2)*results.data[i].Quantita.toFixed(2));
                            } else {
                                //console.log('entro?', item, results.data[i].Costo, results.data[i].Quantita)
                                //totale=parseFloat(totale.toFixed(2))-parseFloat(results.data[i].Costo.toFixed(2)*results.data[i].Quantita.toFixed(2));                               
                            }
                            value = parseFloat(value.toFixed(2))+parseFloat(results.data[i].Costo.toFixed(2)*results.data[i].Quantita.toFixed(2));
                            //console.log('entro?', item, value, 'tanto pè sapè')
                        }
                    }
                    if(item.label === "Quota HostSicilia") {
                        if(varsupp.GuadagnoDisabilitato === 0 /*&& usern.Admin > 0*/) {
                            const prezzo = parseFloat(varsupp.Prezzo.replace(',','.')).toFixed(2)
                            console.log(prezzo);
                            //console.log('entro', guadagno, value, totale, varsupp.Prezzo, varsupp.PercentualeOTA)
                            value = parseFloat(value.toFixed(2))+(prezzo-varsupp.PercentualeOTA/*(varsupp.PercentualeOTA > 0 ? varsupp.Prezzo*(parseInt(varsupp.PercentualeOTA)/100) : 0)-(varsupp.Cedolare > 0 ? parseFloat(prezzo).toFixed(2)*parseInt(varsupp.PercentualeCedolare)/100 : 0))*/)*(parseInt(guadagno)/100)
                            totale = parseFloat(totale.toFixed(2))+(prezzo-varsupp.PercentualeOTA/*(varsupp.PercentualeOTA > 0 ? varsupp.Prezzo*(parseInt(varsupp.PercentualeOTA)/100) : 0)-(varsupp.Cedolare > 0 ? parseFloat(prezzo).toFixed(2)*parseInt(varsupp.PercentualeCedolare)/100 : 0))*/)*(parseInt(guadagno)/100)
                            //console.log(value, totale);
                        }
                    }
                    if(item.label === "Quota Cliente") {
                        if(varsupp.GuadagnoDisabilitato === 0) {
                            if(inverti) {
                                const prezzo = parseFloat(varsupp.Prezzo.replace(',','.')).toFixed(2)
                                value = parseFloat(value.toFixed(2))+(prezzo-varsupp.PercentualeOTA/*(varsupp.PercentualeOTA > 0 ? varsupp.Prezzo*(parseInt(varsupp.PercentualeOTA)/100) : 0)*/)-((prezzo-varsupp.PercentualeOTA)*(parseInt(guadagno)/100))-cedoval-speseval;
                                //totale = (parseFloat(totale.toFixed(2))-(prezzo-prenotazione.PercentualeOTA/*(varsupp.PercentualeOTA > 0 ? varsupp.Prezzo*(parseInt(varsupp.PercentualeOTA)/100) : 0)*/-(prenotazione.Cedolare > 0 ? prezzo*parseInt(prenotazione.PercentualeCedolare)/100 : 0))*(parseInt(100-guadagno)/100))                
                            }
                        } else {
                            const prezzo = parseFloat(varsupp.Prezzo.replace(',','.')).toFixed(2)
                            value = parseFloat(value.toFixed(2))+(prezzo-varsupp.PercentualeOTA-cedoval-speseval);
                        }
                    }
                    //console.log(item.label, value);
                    return {...item, value: parseFloat(value.toFixed(2))}
                })
                for(var i = 0; i < guadagni.length; i++) {
                    totale=parseFloat(totale.toFixed(2))-parseFloat(guadagni[i].Quantita.toFixed(2));
                }
                console.log(newArr);
                setSpese(newArr);
                setSpesaTotale(totale.toFixed(2));
                setListaSpese(results.data);
            })                  
        })
    },[prenotazione])
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        const prenotazioni = pren;
        let totale = 0.0;
        const proprieta = prenotazione.RifProprieta;
        makeRequest.post('/saldo/getsinglepren',{pren})
        .then(async res=>{
            const guadagni = res.data;
            setListaAcconti(res.data)
            //const guadagno = await getPercentualeWithout(proprieta);
            const inverti = await getInvertiHome(proprieta);
            const guadagno = await getPercentualeWithout(proprieta);
            const cedoval = await calcolaCedolare();
            //console.log('guadagno qui è ', guadagno);
            //console.log('entro qui inverti2', inverti);
            const selectedyear = usern.YearSelected;
            makeRequest.post('/spese/get',{proprieta, prenotazioni, selectedyear})
            .then(res=> {
                const speseval = calcolaSpese(res.data);
                console.log('entro request after inverti 2')
                const newArr = spese.map((item) => {
                    let value = 0.00;
                    for(var i = 0; i < res.data.length; i++) {
                        if(item.label === res.data[i].Motivo) {
                            if(item.label !== "Tasse Soggiorno") {
                                totale=parseFloat(totale.toFixed(2))+parseFloat(res.data[i].Costo.toFixed(2)*res.data[i].Quantita.toFixed(2));
                            } else {
                                totale=parseFloat(totale.toFixed(2))-parseFloat(res.data[i].Costo.toFixed(2)*res.data[i].Quantita.toFixed(2));                         
                            }
                            value = parseFloat(value.toFixed(2))+parseFloat((res.data[i].Costo.toFixed(2)*res.data[i].Quantita.toFixed(2)));
                        }
                    }
                    if(item.label === "Quota HostSicilia") {
                        const prezzo = parseFloat(prenotazione.Prezzo.replace(',','.')).toFixed(2)
                        value = parseFloat(value.toFixed(2))+(prezzo-prenotazione.PercentualeOTA/*(varsupp.PercentualeOTA > 0 ? varsupp.Prezzo*(parseInt(varsupp.PercentualeOTA)/100) : 0)-(prenotazione.Cedolare > 0 ? prezzo*parseInt(prenotazione.PercentualeCedolare)/100 : 0)*/)*(parseInt(guadagno)/100)
                        totale = parseFloat(totale.toFixed(2))+(prezzo-prenotazione.PercentualeOTA/*(varsupp.PercentualeOTA > 0 ? varsupp.Prezzo*(parseInt(varsupp.PercentualeOTA)/100) : 0)/-(prenotazione.Cedolare > 0 ? prezzo*parseInt(prenotazione.PercentualeCedolare)/100 : 0)*/)*(parseInt(guadagno)/100)
                    }
                    if(item.label === "Quota Cliente") {
                        if(inverti) {
                            const prezzo = parseFloat(prenotazione.Prezzo.replace(',','.')).toFixed(2)
                            //console.log((prezzo-((prezzo-prenotazione.PercentualeOTA)*(parseInt(guadagno/100))))*parseInt(prenotazione.PercentualeCedolare)/100);
                            value = parseFloat(value.toFixed(2))+(prezzo-prenotazione.PercentualeOTA/*(varsupp.PercentualeOTA > 0 ? varsupp.Prezzo*(parseInt(varsupp.PercentualeOTA)/100) : 0)*/)-((prezzo-prenotazione.PercentualeOTA)*(parseInt(guadagno)/100))-cedoval-speseval;
                            //totale = (parseFloat(totale.toFixed(2))-(prezzo-prenotazione.PercentualeOTA/*(varsupp.PercentualeOTA > 0 ? varsupp.Prezzo*(parseInt(varsupp.PercentualeOTA)/100) : 0)*/-(prenotazione.Cedolare > 0 ? prezzo*parseInt(prenotazione.PercentualeCedolare)/100 : 0))*(parseInt(100-guadagno)/100))                
                        }
                    }
                    return {...item, value: parseFloat(value.toFixed(2))}
                })
                for(var i = 0; i < guadagni.length; i++) {
                    totale=parseFloat(totale.toFixed(2))-parseFloat(guadagni[i].Quantita.toFixed(2));
                }
                //console.log(newArr);
                setSpese(newArr);
                setSpesaTotale(totale.toFixed(2));
                setListaSpese(res.data);
            })
        })
    },[updatespese])
    return(
        <>
        <Col xs={12}>
            <Button onClick={() => close(0)} className='closebutton' variant="light"><XLg/></Button>
        </Col>
        <Col style={{marginTop: '1rem'}} xs={6}>
            {usern?.Admin > 0 ? <Button onClick={() => showModalModify(true)} variant="danger">Modifica</Button> : null}
            {usern?.Admin === 0 && compareTime(prenotazione.DataIn, datadomani) && prenotazione.Admin === -1 ? <Button onClick={() => showModalModify(true)} variant="danger">Modifica</Button> : null}
        </Col>
        <Col style={{marginTop: '1rem'}} xs={6}>
            <h1 style={{textAlign: 'right'}}>{prenotazione.Pagato ? <CheckLg style={{color: 'green'}}/> : null} Prenotazione #{prenotazione.ID}</h1>
            {(() => {
                const arr = [];
                if(prenotazione.PercentualeOTA > 0)
                    arr.push(<h4 style={{textAlign: 'right'}}>OTA: {prenotazione.OTA}  (€{prenotazione.PercentualeOTA})</h4>)
                if(prenotazione.Cedolare > 0) 
                    arr.push(<h4 style={{textAlign: 'right'}}>Cedolare secca: {prenotazione.PercentualeCedolare}% (€{cedolarevalue})</h4>)
                if(getPercentualeCliente() > 0)
                    arr.push(<h4 style={{textAlign: 'right'}}>Guadagno Cliente: €{getPercentualeCliente()}</h4>)
                if(getTassaSoggiorno() > 0)
                    arr.push(<h4 style={{textAlign: 'right'}}>Tassa di soggiorno: €{getTassaSoggiorno()}</h4>)
                return arr;
            })()}
        </Col>
        <Col style={{textAlign: 'left'}} xs={12} md={6}>
            <div style={{margin: '1rem'}}>
                {load ? <>
                <p>Nome - Cognome: <b>{prenotazione.Nome} -  {prenotazione.Cognome}</b></p>
                <p>Prezzo: <b>{parseFloat(prenotazione.Prezzo.replace(',','.')).toFixed(2)}€</b></p>
                <p>Cellulare: <b><a href={`tel:${prenotazione.Cellulare}`}>{prenotazione.Cellulare}</a></b></p>
                <p>Nazionalità: <b>{prenotazione.Nazionalita}</b></p>
                <p>Check-In: <b>{YMD(prenotazione.DataIn)}</b></p>
                <p>Check-Out: <b>{YMD(prenotazione.DataOut)}</b></p>
                {usern.Admin > 0 ? <p>Colazione inclusa: <b>{prenotazione.Colazione ? "Si" : "No"}</b></p>: null}
                <p>Adulti: <b>{prenotazione.Adulti}</b> - Bambini: <b>{prenotazione.Bambini}</b> - Neonati: <b>{prenotazione.Neonati}</b></p>
                <p>Culla: <b>{prenotazione.Culla}</b> - Seggiolone: <b>{prenotazione.Seggiolone}</b> - Bagnetto: <b>{prenotazione.Bagnetto}</b></p>
                {(prenotazione.Admin === -1 || usern.Admin > 0 ?<FloatingLabel label="Note">
                    <Form.Control as="textarea"
                        rows={4}
                        value={prenotazione.Note}
                        className='mb-3'
                        placeholder="Note"
                        disabled="true"
                        style={{width: '50% !important'}}
                    />
                </FloatingLabel>
                : null)}
                </>
                : null}
            </div>
        </Col>
        <Col style={{textAlign: 'right'}} xs={12} md={6}>
            <h1>Spese totali: {spesatotale}€</h1>
            {/*PieActiveArc(spese)*/NewGraphic(spese)
            }
            {usern?.Admin > 0 ? <Button style={{margin: '0.5rem'}} onClick={()=>showModalAcconto(true)} variant="success">Aggiungi saldo/acconto</Button> : null}
            {usern?.Admin > 0 ? <Button style={{margin: '0.5rem'}} onClick={()=>showModal(true)} variant="primary">Aggiungi spese</Button> : null}
        </Col>
        {listaspese.length > 0 ?
        <Col xs={12}>
            <h1>Spese</h1>
            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Spesa</th>
                        <th>Costo</th>
                        <th>Quantità</th>
                        <th>Data</th>
                        {usern?.Admin > 0 ? <th>Elimina</th> : null}
                    </tr>
                </thead>
                <tbody>
                    {(() => {
                        const arr = [];
                        let conto = 0;
                        listaspese.map((item) => {
                            conto++;
                            return arr.push(<tr>
                                <td>{conto}</td>
                                <td>{item.Motivo}</td>
                                <td>{item.Costo}€</td>
                                <td>{item.Quantita}</td>
                                <td>{YMD(item.DataInserimento)}</td>
                                {usern?.Admin > 0 ? <td><Button id={item.ID} variant="danger" onClick={(e) => handleClickDelete(e.currentTarget.id)}><XLg/></Button></td> : null}
                            </tr>)
                        })
                        return arr;
                    })()}
                </tbody>
            </Table>
        </Col> : null }
        {listaacconti.length > 0 ?
        <Col xs={12}>
            <h1>Saldi/Acconti</h1>
            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Quantità</th>
                        <th>Data</th>
                        {usern?.Admin > 0 ? <th>Elimina</th> : null}
                    </tr>
                </thead>
                <tbody>
                {(() => {
                    const arr = [];
                    let conto = 0;
                    listaacconti.map((item) => {
                        conto++;
                        return arr.push(<tr key={conto}><td>{conto}</td><td>{item?.Quantita}€</td><td>{YMD(item?.Data)}</td>{usern?.Admin > 0 ? <td><Button variant="danger" onClick={() => handleDeleteAcconto(item.ID)}><XLg/></Button></td> : null}</tr>)
                    })
                    return arr;
                })()}
                </tbody>
            </Table>
        </Col> : null }
        <Modal
            show={modalModify}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => showModalModify(false)}
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Modifica Prenotazione
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col style={{marginBottom:'0.5rem'}} xs={6}>
                            <FloatingLabel label="Nome">
                                <Form.Control
                                    type="text"
                                    value={modifypren.Nome}
                                    placeholder='Nome'
                                    onChange={(e) => setModifyPren({...modifypren, Nome: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginBottom:'0.5rem',textAlign: 'right'}} xs={6}>
                            <FloatingLabel label="Cognome">
                                <Form.Control
                                    type="text"
                                    value={modifypren.Cognome}
                                    placeholder='Cognome'
                                    onChange={(e) => setModifyPren({...modifypren, Cognome: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginBottom:'0.5rem'}} xs={6}>
                            <FloatingLabel label="Nazionalità">
                                <Form.Control
                                    type="text"
                                    value={modifypren.Nazionalita}
                                    placeholder='Nazionalità'
                                    onChange={(e) => setModifyPren({...modifypren, Nazionalita: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginBottom:'0.5rem'}} xs={6}>
                            <FloatingLabel label="Cellulare">
                                <Form.Control
                                    type="phone"
                                    value={modifypren.Cellulare}
                                    placeholder='Cellulare'
                                    onChange={(e) => setModifyPren({...modifypren, Cellulare: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginBottom:'0.5rem'}} xs={6}>
                            <FloatingLabel label="Check In">
                                <Form.Control
                                    type="date"
                                    value={modifypren.DataIn}
                                    placeholder='Check In'
                                    onChange={(e) => setModifyPren({...modifypren, DataIn: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginBottom:'0.5rem'}} xs={6}>
                            <FloatingLabel label="Ora CheckIn">
                                <Form.Control
                                    type="hour"
                                    value={modifypren.OraIn}
                                    placeholder='Ora CheckIn'
                                    onChange={(e) => setModifyPren({...modifypren, OraIn: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>               
                        <Col style={{marginBottom:'0.5rem'}} xs={6}>
                            <FloatingLabel label="Check Out">
                                <Form.Control
                                    type="date"
                                    value={modifypren.DataOut}
                                    placeholder='Check Out'
                                    onChange={(e) => setModifyPren({...modifypren, DataOut: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginBottom:'0.5rem'}} xs={6}>
                            <FloatingLabel label="Ora CheckOut">
                                <Form.Control
                                    type="hour"
                                    value={modifypren.OraOut}
                                    placeholder='Ora CheckOut'
                                    onChange={(e) => setModifyPren({...modifypren, OraOut: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>  
                        <Col style={{marginBottom:'0.5rem'}} xs={6}>
                            <FloatingLabel label="Adulti">
                                <Form.Control
                                    type="number"
                                    value={modifypren.Adulti}
                                    placeholder='Adulti'
                                    onChange={(e) => handleNumber(e,"Adulti")}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginBottom:'0.5rem'}} xs={6}>
                            <FloatingLabel label="Bambini">
                                <Form.Control
                                    type="number"
                                    value={modifypren.Bambini}
                                    placeholder='Bambini'
                                    onChange={(e) => handleNumber(e,"Bambini")}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginBottom:'0.5rem'}} xs={6}>
                            <FloatingLabel label="Neonati">
                                <Form.Control
                                    type="number"
                                    value={modifypren.Neonati}
                                    placeholder='Neonati'
                                    onChange={(e) => handleNumber(e,"Neonati")}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginBottom:'0.5rem'}} xs={6}>
                            <FloatingLabel label="Culla">
                                <Form.Control
                                    type="number"
                                    value={modifypren.Culla}
                                    placeholder='Culla'
                                    onChange={(e) => handleNumber(e,"Culla")}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginBottom:'0.5rem'}} xs={6}>
                            <FloatingLabel label="Seggiolone">
                                <Form.Control
                                    type="number"
                                    value={modifypren.Seggiolone}
                                    placeholder='Seggiolone'
                                    onChange={(e) => handleNumber(e,"Seggiolone")}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginBottom:'0.5rem'}} xs={6}>
                            <FloatingLabel label="Bagnetto">
                                <Form.Control
                                    type="number"
                                    value={modifypren.Bagnetto}
                                    placeholder='Bagnetto'
                                    onChange={(e) => handleNumber(e,"Bagnetto")}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginBottom:'0.5rem', marginTop: '1rem'}} xs={6}>
                            <FloatingLabel label="Prezzo">
                                <Form.Control
                                    type="phone"
                                    value={modifypren.Prezzo}
                                    placeholder='Prezzo €'
                                    onChange={(e) => handleNumber(e,"Prezzo")}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginBottom:'0.5rem', marginTop: '1rem'}} xs={6}>
                            <FloatingLabel label="OTA">
                                <Form.Select value={modifypren.OTA} onChange={(e) => setModifyPren({...modifypren, OTA: e.target.value})}>
                                    <option value="Privato">Privato</option>
                                    <option value="Booking.com">Booking.com</option>
                                    <option value="AirBnb">AirBnb</option>
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        {usern?.Admin > 0 ?
                        <>
                        <Col style={{marginBottom:'0.5rem', marginTop: '1rem'}} xs={6}>
                            <FloatingLabel label="OTA €">
                                <Form.Control
                                    type="phone"
                                    value={modifypren.PercentualeOTA}
                                    placeholder='OTA €'
                                    onChange={(e) => handleNumber(e,"Percentuale")}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginBottom:'0.5rem', marginTop: '1rem'}} xs={6}>
                            <Form.Check
                                type="checkbox"
                                label="Cedolare secca"
                                checked={modifypren.Cedolare}
                                onChange={(e) => setModifyPren({...modifypren, Cedolare: e.target.checked})}
                            />
                        </Col>
                        {modifypren.Cedolare ? 
                        <Col style={{marginBottom:'0.5rem', marginTop: '0.5rem'}} xs={6}>
                            <Form.Select value={modifypren.PercentualeCedolare} onChange={(e) => setModifyPren({...modifypren, PercentualeCedolare: e.target.value})}>
                                <option value="21">21%</option>
                                <option value="26">26%</option>
                            </Form.Select>
                        </Col> : null}
                        <Col style={{marginBottom:'0.5rem', marginTop: '1rem'}} xs={6}>
                            <Form.Check
                                type="checkbox"
                                label="Pagato"
                                checked={modifypren.Pagato}
                                onChange={(e) => setModifyPren({...modifypren, Pagato: e.target.checked})}
                            />
                        </Col>
                        <Col style={{marginBottom:'0.5rem', marginTop: '1rem'}} xs={6}>
                            <Form.Check
                                type="checkbox"
                                label="Disabilita guadagno %"
                                checked={modifypren.GuadagnoDisabilitato}
                                onChange={(e) => setModifyPren({...modifypren, GuadagnoDisabilitato: e.target.checked})}
                            />
                        </Col>
                        </> : null }
                        {usern.Admin > 0 ?
                        <>
                        <Col style={{marginBottom:'0.5rem', marginTop: (usern?.Admin ? '1rem': '2rem')}} xs={usern?.Admin ? 6 : 6}>
                            <Form.Check
                                type="checkbox"
                                label="Colazione inclusa"
                                checked={modifypren.Colazione}
                                onChange={(e) => setModifyPren({...modifypren, Colazione: e.target.checked})}
                            />
                        </Col>
                        <Col style={{marginBottom:'0.5rem', marginTop: (usern?.Admin ? '1rem': '2rem')}} xs={usern?.Admin ? 6 : 6}>
                            <Form.Check
                                type="checkbox"
                                label="Limita Admin (disabilita le note e la modifica)"
                                checked={(modifypren.Admin == 1 ? true : false)}
                                onChange={(e) => setModifyPren({...modifypren, Admin: e.target.checked})}
                            />
                        </Col>
                        <Col style={{marginBottom:'0.5rem', marginTop: (usern?.Admin ? '1rem':'2rem')}} xs={12}>
                            <FloatingLabel label="Colazione Extra">
                                <Form.Control
                                    type="text"
                                    value={modifypren.ColazioneExtra}
                                    placeholder="Prezzo..."
                                    onChange={(e) => setModifyPren({...modifypren, ColazioneExtra: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginTop:'0.5rem'}} xs={12}>
                            <FloatingLabel label="Biancheria Extra">
                                <Form.Control
                                    type="text"
                                    value={modifypren.BiancheriaExtra}
                                    placeholder="Prezzo..."
                                    onChange={(e) => setModifyPren({...modifypren, BiancheriaExtra: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginTop:'0.5rem'}} xs={12}>
                            <FloatingLabel label="Pulizia Extra">
                                <Form.Control
                                    type="text"
                                    value={modifypren.PuliziaExtra}
                                    placeholder="Prezzo..."
                                    onChange={(e) => setModifyPren({...modifypren, PuliziaExtra: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginTop:'0.5rem'}} xs={12}>
                            <FloatingLabel label="CheckIn Extra">
                                <Form.Control
                                    type="text"
                                    value={modifypren.CheckInExtra}
                                    placeholder="Prezzo..."
                                    onChange={(e) => setModifyPren({...modifypren, CheckInExtra: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>
                        </>
                        : null}
                        {(usern.Admin > 0 || modifypren.Admin == -1 ? 
                        <Col style={{marginBottom:'0.5rem'}} xs={12}>
                            <FloatingLabel label="Note">
                                <Form.Control as="textarea"
                                    rows={5}
                                    value={modifypren.Note}
                                    placeholder='Note'
                                    onChange={(e) => setModifyPren({...modifypren, Note: e.target.value})}
                                />
                            </FloatingLabel>
                        </Col>
                        : null)}
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={disabledButton} variant="danger" onClick={handleDeletePren}>Elimina</Button>
                <Button disabled={disabledButton} variant="success" onClick={handleSavePren}>Salva</Button>
                <Button variant="secondary" onClick={() => showModalModify(false)}>Chiudi</Button>
            </Modal.Footer>
        </Modal>
        {usern?.Admin > 0 ? <>
        <Modal
            show={modalSpese}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => showModal(false)}
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Inserisci spese
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Seleziona</h4>
                <Container>
                    <Row>
                        <Col style={{marginTop:'0.5rem'}} xs={3}>
                            Pulizie
                        </Col>
                        <Col style={{marginTop:'0.5rem',textAlign: 'right'}} xs={3}>
                            <Button variant={selectedpulizia ? 'success' : 'light'} onClick={handleClickPulizia}><CheckLg/></Button>
                        </Col>
                        <Col style={{marginTop:'0.5rem', textAlign: 'right'}} xs={6}>
                            <FloatingLabel label="€">
                                <Form.Control
                                    type="number"
                                    value={newspesa.puliziaprice}
                                    onChange={(e) => handleNumber(e,"PuliziaPrice")}
                                    placeholder="€"
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginTop:'0.5rem'}} xs={3}>
                            Check In/Out
                        </Col>
                        <Col style={{marginTop:'0.5rem',textAlign: 'right'}} xs={3}>
                            <Button variant={selectedcheckin ? 'success' : 'light'} onClick={handleClickCheck}><CheckLg/></Button>
                        </Col>
                        <Col style={{marginTop:'0.5rem', textAlign: 'right'}} xs={6}>
                            <FloatingLabel label="€">
                                <Form.Control
                                    type="number"
                                    value={newspesa.checkinprice}
                                    onChange={(e) => handleNumber(e,"CheckInPrice")}
                                    placeholder="€"
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginTop:'0.5rem'}} xs={6}>
                            Check In/Out Tardivo
                        </Col>
                        <Col style={{marginTop:'0.5rem', textAlign: 'right'}} xs={6}>
                            <Form.Select onChange={(e) => setNewSpesa({...newspesa, tardivo: parseInt(e.target.value)})}>
                                <option value="0">Seleziona</option>
                                <option value="20">Tardivo [20:00-22:00] (+20€)</option>
                                <option value="30">Tardivo [22:00-24:00] (+30€)</option>
                                <option value="50">Notturno [24:00+] (+50€)</option>
                            </Form.Select>
                        </Col>
                        <Col style={{marginTop:'0.5rem'}} xs={3}>
                            Biancheria
                        </Col>
                        <Col style={{marginTop:'0.5rem', textAlign: 'right'}} xs={3}>
                            <Form.Select onChange={(e) => setNewSpesa({...newspesa, biancheria: parseInt(e.target.value)})}>
                                <option value="0">Seleziona</option>
                                {getpersone()}
                            </Form.Select>
                        </Col>
                        <Col style={{marginTop:'0.5rem', textAlign: 'right'}} xs={6}>
                            <FloatingLabel label="€">
                                <Form.Control
                                    type="number"
                                    value={newspesa.biancheriaprice}
                                    onChange={(e) => handleNumber(e,"BiancheriaPrice")}
                                    placeholder="€"
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginTop:'0.5rem'}} xs={3}>
                            <p>Culla</p>
                        </Col>
                        <Col style={{marginTop:'0.5rem', textAlign: 'right'}} xs={3}>
                            <Form.Select onChange={(e) => setNewSpesa({...newspesa, culla: parseInt(e.target.value)})}>
                                <option value="0">Seleziona</option>
                                {getnotti()}
                            </Form.Select>
                        </Col>
                        <Col style={{marginTop:'0.5rem', textAlign: 'right'}} xs={6}>
                            <FloatingLabel label="€">
                                <Form.Control
                                    type="number"
                                    value={newspesa.cullaprice}
                                    onChange={(e) => handleNumber(e,"CullaPrice")}
                                    placeholder="€"
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginTop:'0.5rem'}} xs={3}>
                            <p>Seggiolone</p>
                        </Col>
                        <Col style={{marginTop:'0.5rem', textAlign: 'right'}} xs={3}>
                            <Form.Select onChange={(e) => setNewSpesa({...newspesa, seggiolone: parseInt(e.target.value)})}>
                                <option value="0">Seleziona</option>
                                {getnotti()}
                            </Form.Select>
                        </Col>
                        <Col style={{marginTop:'0.5rem', textAlign: 'right'}} xs={6}>
                            <FloatingLabel label="€">
                                <Form.Control
                                    type="number"
                                    value={newspesa.seggioloneprice}
                                    onChange={(e) => handleNumber(e,"SeggiolonePrice")}
                                    placeholder="€"
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginTop:'0.5rem'}} xs={3}>
                            <p>Bagnetto</p>
                        </Col>
                        <Col style={{marginTop:'0.5rem', textAlign: 'right'}} xs={3}>
                            <Form.Select onChange={(e) => setNewSpesa({...newspesa, bagnetto: parseInt(e.target.value)})}>
                                <option value="0">Seleziona</option>
                                {getnotti()}
                            </Form.Select>
                        </Col>
                        <Col style={{marginTop:'0.5rem', textAlign: 'right'}} xs={6}>
                            <FloatingLabel label="€">
                                <Form.Control
                                    type="number"
                                    value={newspesa.bagnettoprice}
                                    onChange={(e) => handleNumber(e,"BagnettoPrice")}
                                    placeholder="€"
                                />
                            </FloatingLabel>
                        </Col>
                        <Col style={{marginTop:'0.5rem'}} xs={12}>
                            <p>Tassa di soggiorno</p>
                        </Col>
                        <Col style={{marginTop:'0.5rem', textAlign: 'right'}} xs={4}>
                            <Form.Select onChange={(e) => setTassa({...tassa, persone: parseInt(e.target.value)})}>
                                <option value="0">Seleziona</option>
                                {getpersone()}
                            </Form.Select>
                        </Col>
                        <Col style={{marginTop:'0.5rem', textAlign: 'right'}} xs={4}>
                            <Form.Select onChange={(e) => setTassa({...tassa, notti: parseInt(e.target.value)})}>
                                <option value="0">Seleziona</option>
                                {getnotti()}
                            </Form.Select>
                        </Col>
                        <Col style={{marginTop:'0.5rem', textAlign: 'right'}} xs={4}>
                            <Form.Select onChange={(e) => setTassa({...tassa, prezzo: parseFloat(e.target.value).toFixed(2)})}>
                                <option value="0">Seleziona</option>
                                <option value="1">1,00€</option>
                                <option value="1.50">1,50€</option>
                                <option value="2">2,00€</option>
                            </Form.Select>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={disabledButton} variant="success" onClick={handleSaveSpese}>Salva</Button>
                <Button variant="secondary" onClick={() => showModal(false)}>Chiudi</Button>
            </Modal.Footer>
            </Modal>
            <Modal
                show={modalAcconto}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => showModalAcconto(false)}
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Inserisci saldo/acconto
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Saldo/Acconto</h4>
                    <Container>
                        <Row>
                            <Col style={{margin:'0.5rem'}} xs={5}>
                                <FloatingLabel label="€">
                                <Form.Control
                                    type="number"
                                    placeholder="€"
                                    onChange={(e) => handleNumber(e, 'acconto')}
                                />
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={disabledButton} variant="success" onClick={handleSaveAcconto}>Salva</Button>
                    <Button variant="secondary" onClick={() => showModalAcconto(false)}>Chiudi</Button>
                </Modal.Footer>
            </Modal> </>: null}
        </>
    );
}

export default SettingPren;