import ReactECharts from "echarts-for-react"; 


export default function NewGraphicBar(data) {
    const populateArr = (type) => {
        let arr = [];
        switch(type) {
            case 'label': {
                for(let i = 0; i < data.length; i++) {
                    arr.push(data[i].label);
                }
                break;
            }
            case 'value': {
                data.map((item) => {
                   arr.push(parseFloat(item.value).toFixed(2));
                })
                break;
            }
        }
        return arr;
    }
    const arrXAxys = populateArr('label');
    const arrYAxys = populateArr('value');
    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: arrXAxys,
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '€',
              type: 'bar',
              barWidth: '60%',
              data: arrYAxys
            }
          ]
    }
    return (
        <ReactECharts option={option}/>
    );
}
/*export default function NewGraphic(data) {
    const populateArr = (type) => {
        let arr = [];
        switch(type) {
            case 'label': {
                for(let i = 0; i < data.length; i++) {
                    arr.push(data[i].label);
                }
                break;
            }
            case 'value': {
                arr = data.map((item) => {
                    return {value: item.value, name: item.label}
                })
                break;
            }
        }
        return arr;
    }

    const arrXAxys = populateArr('label');
    const arrYAxys = populateArr('value');
    console.log(arrXAxys, arrYAxys, data);
    const option = {
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
            data: arrXAxys,
        },
        series: [
            {
                name: 'Spese',
                type: 'pie',
                radius: ['45%', '60%'],
                labelLine: {
                length: 30
                },
                label: {
                formatter: '{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ',
                backgroundColor: '#F6F8FC',
                borderColor: '#8C8D8E',
                borderWidth: 1,
                borderRadius: 4,
                rich: {
                    a: {
                    color: '#6E7079',
                    lineHeight: 22,
                    align: 'center'
                    },
                    hr: {
                    borderColor: '#8C8D8E',
                    width: '100%',
                    borderWidth: 1,
                    height: 0
                    },
                    b: {
                    color: '#4C5058',
                    fontSize: 14,
                    fontWeight: 'bold',
                    lineHeight: 33
                    },
                    per: {
                    color: '#fff',
                    backgroundColor: '#4C5058',
                    padding: [3, 4],
                    borderRadius: 4
                    }
                }
                },
                data: arrYAxys,
            }
        ]
    };
    return (
        <ReactECharts option={option}/>
    );
}*/